import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, Paper, Container, Button, Stack, Modal, Input, InputLabel, TextField, Divider, Select, FormControl, MenuItem, ButtonGroup, OutlinedInput, SelectChangeEvent, Grid } from "@mui/material"
import { useTheme } from "../../../services/theme";
import { Colors } from "../../../types";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../services/authContext";

export const Workspace = () => {
    const { me } = useAuth()
    const [openModal, setOpenModal] = useState(false);
    const theme = useTheme()
    const navigate = useNavigate()

    return (
        <div>
            <Typography variant="h5">Workspace</Typography>

            <Container sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4, width: '100%' }}>
                <Grid container spacing={2}>
                    {me?.role !== 'staff' && <Grid item xs={6} sm={6}>
                        <Box 
                            sx={{ 
                                widows: '100%', 
                                // height: '200px', 
                                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                                borderRadius: 1,
                                borderTop: '2px solid blue',
                                padding: 2,
                            }}
                        >
                            <Typography variant="h6">USERS</Typography>
                            <Typography style={{ marginTop: 10 }} variant="subtitle2">
                                Add, edit, and remove your workspace users here. 
                                Update their numbers, permissions, and settings.
                            </Typography>
                            <Divider style={{ marginTop: 30 }}></Divider>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => navigate('users') } style={{ marginTop: 20 }} variant="contained" size="medium">Manage</Button>
                            </Box>
                        </Box>
                    </Grid>}
                    {me?.role === 'admin' && <Grid item xs={6} sm={6}>
                        <Box 
                            sx={{ 
                                widows: '100%',
                                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                                borderRadius: 1,
                                borderTop: '2px solid skyblue',
                                padding: 2,
                            }}
                        >
                             <Typography variant="h6">SENDING SERVERS</Typography>
                            <Typography style={{ marginTop: 10 }} variant="subtitle2">
                                Add, edit, and remove your sending servers here. 
                                {/* Update their numbers, permissions, and settings. */}
                            </Typography>
                            <Divider style={{ marginTop: 30 }}></Divider>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => navigate('servers') } style={{ marginTop: 20 }} variant="contained" size="medium">Manage</Button>
                            </Box>
                        </Box>
                    </Grid>}
                    {me?.role === 'admin' && <Grid item xs={6} sm={6}>
                        <Box 
                            sx={{ 
                                widows: '100%',
                                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                                borderRadius: 1,
                                borderTop: '2px solid green',
                                padding: 2,
                            }}
                        >
                             <Typography variant="h6">SERVER GROUPS</Typography>
                            <Typography style={{ marginTop: 10 }} variant="subtitle2">
                                Add, edit, and remove your server groups here. 
                                {/* Update their numbers, permissions, and settings. */}
                            </Typography>
                            <Divider style={{ marginTop: 30 }}></Divider>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => navigate('serverGropus') } style={{ marginTop: 20 }} variant="contained" size="medium">Manage</Button>
                            </Box>
                        </Box>
                    </Grid>}
                    {me?.role !== 'staff' && <Grid item xs={6} sm={6}>
                        <Box 
                            sx={{ 
                                widows: '100%',
                                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                                borderRadius: 1,
                                borderTop: '2px solid orange',
                                padding: 2,
                            }}
                        >
                                <Typography variant="h6">KEYWORDS</Typography>
                            <Typography style={{ marginTop: 10 }} variant="subtitle2">
                                Add, edit, and remove your keywords here. 
                                {/* Update their numbers, permissions, and settings. */}
                            </Typography>
                            <Divider style={{ marginTop: 30 }}></Divider>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => navigate('keywords') } style={{ marginTop: 20 }} variant="contained" size="medium">Manage</Button>
                            </Box>
                        </Box>
                    </Grid>}
                    <Grid item xs={6} sm={6}>
                        <Box 
                            sx={{ 
                                widows: '100%',
                                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                                borderRadius: 1,
                                borderTop: '2px solid red',
                                padding: 2,
                            }}
                        >
                             <Typography variant="h6">BLOCKED NUMNERS</Typography>
                            <Typography style={{ marginTop: 10 }} variant="subtitle2">
                                Add, edit, and remove your blocked numbers here. 
                                {/* Update their numbers, permissions, and settings. */}
                            </Typography>
                            <Divider style={{ marginTop: 30 }}></Divider>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => navigate('blocked') } style={{ marginTop: 20 }} variant="contained" size="medium">Manage</Button>
                            </Box>
                        </Box>
                    </Grid>
                    <Grid item xs={6} sm={6}>
                        <Box 
                            sx={{ 
                                widows: '100%',
                                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                                borderRadius: 1,
                                borderTop: '2px solid yellow',
                                padding: 2,
                            }}
                        >
                             <Typography variant="h6">QUICK RESPONSES</Typography>
                            <Typography style={{ marginTop: 10 }} variant="subtitle2">
                                Add, edit, and remove your quick responses here. 
                                {/* Update their numbers, permissions, and settings. */}
                            </Typography>
                            <Divider style={{ marginTop: 30 }}></Divider>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => navigate('responses') } style={{ marginTop: 20 }} variant="contained" size="medium">Manage</Button>
                            </Box>
                        </Box>
                    </Grid>
                    {me?.role !== 'staff' && <Grid item xs={6} sm={6}>
                        <Box 
                            sx={{ 
                                widows: '100%',
                                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                                borderRadius: 1,
                                borderTop: '2px solid lightgreen',
                                padding: 2,
                            }}
                        >
                             <Typography variant="h6">LISTS</Typography>
                            <Typography style={{ marginTop: 10 }} variant="subtitle2">
                                Add, edit, and remove your lists here. 
                                {/* Update their numbers, permissions, and settings. */}
                            </Typography>
                            <Divider style={{ marginTop: 30 }}></Divider>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => navigate('lists') } style={{ marginTop: 20 }} variant="contained" size="medium">Manage</Button>
                            </Box>
                        </Box>
                    </Grid>}
                    {me?.role === 'admin' && <Grid item xs={6} sm={6}>
                        <Box
                            sx={{
                                widows: '100%',
                                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                                borderRadius: 1,
                                borderTop: '2px solid lightgreen',
                                padding: 2,
                            }}
                        >
                            <Typography variant="h6">SIM</Typography>
                            <Typography style={{ marginTop: 10 }} variant="subtitle2">
                                Checking active SIM cards
                            </Typography>
                            <Divider style={{ marginTop: 30 }}></Divider>

                            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                                <Button onClick={() => navigate('sim') } style={{ marginTop: 20 }} variant="contained" size="medium">Manage</Button>
                            </Box>
                        </Box>
                    </Grid>}
                </Grid>
            </Container>
       
        </div>
    )
}
