import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import { Request } from "../request";
import { toast } from "react-toastify";

export interface IBlocked {
  number: string;
  note: string;
  createdAt: string;
}

type State = {
  blocked: IBlocked[];
  limit: number;
  offset: number;
  isLoading: boolean;
};

const initialState: State = {
  blocked: [],
  limit: 8,
  offset: 0,
  isLoading: false,
};


export const getBlocked = createAsyncThunk(
  "/blocked/getAll",
  async (_, { getState, rejectWithValue }: any) => {
    try {
        const { offset, limit } = getState().blocked as State
        const response = await Request.get(`blocked?offset=${offset}&limit=${limit}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const createBlocked = createAsyncThunk(
  "/blocked/create",
  async (payload: any, { rejectWithValue }) => {
    try {
        const response = await Request.post('blocked', payload)
        toast('created', { type: 'success' })
        return response
    } catch (error: any) {
        toast('some error', { type: 'error' })
      return rejectWithValue(error);
    }
  }
);

export const updateBlocked = createAsyncThunk(
  "/blocked/update",
  async ({ id, payload }: any, { rejectWithValue }) => {
    try {
        const response = await Request.post(`blocked/${id}`, payload)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const deleteBlocked = createAsyncThunk(
  "/blocked/delete",
  async (id: string, { rejectWithValue }) => {
    try {
        const response = await Request.delete(`blocked/${id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);


const blockedSlice = createSlice({
  name: "blocked",
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      // get all
      .addMatcher(
        (action) => action.type === getBlocked.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getBlocked.fulfilled.type,
        (state, action: PayloadAction<{blocked: IBlocked[]}>) => {
            state.blocked = [...action.payload.blocked]
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getBlocked.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // create
      .addMatcher(
        (action) => action.type === createBlocked.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === createBlocked.fulfilled.type,
        (state, action: PayloadAction<{blocked: IBlocked}>) => {
            state.blocked = [...current(state.blocked), action.payload.blocked]
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === createBlocked.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // update
      .addMatcher(
        (action) => action.type === updateBlocked.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === updateBlocked.fulfilled.type,
        (state, action: PayloadAction<{blocked: IBlocked}>) => {
            state.blocked = current(state).blocked.map(blocked => blocked.number === action.payload.blocked.number ? action.payload.blocked : blocked)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === updateBlocked.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // delete
      .addMatcher(
        (action) => action.type === deleteBlocked.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === deleteBlocked.fulfilled.type,
        (state, action: PayloadAction<{id: string}>) => {
            state.blocked = current(state).blocked.filter(blocked => blocked.number !== action.payload.id)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === deleteBlocked.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
  },
});

export const { } = blockedSlice.actions;

export default blockedSlice.reducer;
