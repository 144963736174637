import { Box, Button, Modal, Stack, Switch, TextField, Typography } from "@mui/material"
import { Colors } from "../../../../types"
import { ModalEditTemplate, MultiSelectCampaignTemplates } from "../utils"
import { useTheme } from "../../../../services/theme"
import { useEffect, useState } from "react"
import { useDispatch, useSelector } from "../../../../redux/store"
import { useAuth } from "../../../../services/authContext"
import {ICampaign} from "../../../../redux/campaign/campaign.interface";
import {testCampaignSms} from "../../../../redux/campaign/campaign.request";

type Props = {
    campaign: ICampaign
    message: string
    setMessage: (v: any) => void
    selectedTemplates: any[]
    setSelectedTemplates: (v: any) => void
    selectedTemplate: any
    setSelectedTemplate: (v: any) => void
    handleSaveMessage: () => void
}

export const CampaignTemplates = ({ handleSaveMessage, campaign, message, setMessage, selectedTemplates, setSelectedTemplates, selectedTemplate, setSelectedTemplate }: Props) => {
    const { me } = useAuth()
    const { templates } = useSelector(store => store.templates)
    const [number, setNumber] = useState('')
    const [isOpenModalEdit, setIsOpenModalEdit] = useState(false)
    const theme = useTheme()
    const dispatch = useDispatch()
    
    useEffect(() => {
        setMessage(selectedTemplate?.campaign_templates?.message)
    }, [selectedTemplate])

    const sendTest = () => {
        dispatch(testCampaignSms({ id: campaign.id, payload: { message: message, number } }))
        setNumber('')
    }

    return (
        <Box
            sx={{ 
                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                marginTop: 1,
                padding: 2,
                position: 'relative',
            }}
        >
            <Typography variant="subtitle1">EDIT YOUR TEXT MESSAGE</Typography>
            <Typography variant="caption">Make sure to interpolate your variables like {'{name}'}. The name should match your xlsx column headers.</Typography>
            <Stack marginTop={3} display={'flex'} justifyContent={'space-between'} direction={{ xs: 'column', sm: 'row' }}  spacing={4}>
                {/* <FormControl style={{ marginTop: 10, width: '100%' }}>
                    <InputLabel sx={{ color: Colors.MAIN_LIGTH }} id="demo-simple-select-label">Select Template</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        defaultValue={campaign.template?.id}
                        label="Select Template"
                        onChange={(data) =>  {handleUpdate(campaign.id, { template_id: data.target.value, message: templates.find(t => t.id === data.target.value)?.text || '' })}}
                    >
                        {
                            templates.map(template => <MenuItem key={template.id} value={template.id}>{template.name}</MenuItem>)
                        }
                    </Select>
                </FormControl> */}
                <MultiSelectCampaignTemplates
                    campaignTemplates={campaign.templates}
                    array={templates} 
                    selected={selectedTemplates} setSelected={setSelectedTemplates} 
                    setSelectedTemplate={setSelectedTemplate}
                ></MultiSelectCampaignTemplates>
                <Button onClick={() => setIsOpenModalEdit(true)} size="medium" variant="contained">Edit Templates</Button>
            </Stack>

            <TextField
                value={message}
                onChange={(e) => { setMessage(e.target.value) }}
                style={{ marginTop: 20 }}
                fullWidth
                placeholder="Message template"
                multiline
                rows={4}
            />

            <Button onClick={() => { setMessage((prev: string) => (prev || '' )+ ` {@Pick( | | )}`) }} style={{ marginTop: 10, marginRight: 6 }} variant="contained">PICK</Button>
            <Button onClick={() => { setMessage((prev: string) => (prev || '' )+ ` {number}`) }} style={{ marginTop: 10, marginRight: 6 }} variant="contained">number</Button>
            <Button onClick={() => { setMessage((prev: string) => (prev || '') + ` {first_name}`) }} style={{ marginTop: 10, marginRight: 6 }} variant="contained">first_name</Button>
            <Button onClick={() => { setMessage((prev: string) => (prev || '') + ` {last_name}`) }} style={{ marginTop: 10, marginRight: 6 }} variant="contained">last_name</Button>
            <Box>
                {campaign.fields.map((field: any) => (
                    <Button
                        key={field.key}
                        onClick={() => {
                            setMessage((prev: string) => prev + ` {${field.key}}`);
                        }}
                        style={{ marginTop: 10, marginRight: 6 }}
                        variant="contained"
                    >
                        {field.key}
                    </Button>
                ))}
            </Box>

            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={() => handleSaveMessage()} size="large" style={{ marginTop: 20 }} variant="contained">Save</Button>
            </Box>

            {/* <Box sx={{ marginTop: 2, display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
                <Typography variant="subtitle2">Add 'Reply STOP to Opt-Out' to your message.</Typography>
                <Switch></Switch>
            </Box> */}

            <Box sx={{ marginTop: 2 }}>
                <Typography variant="subtitle2">TEST YOUR MESSAGE</Typography>

                <Box sx={{ marginTop: 1, display: 'flex', justifyContent: 'space-between',  }}>
                    <TextField value={number} onChange={(e) => setNumber(e.target.value)} placeholder="+1 702 123 4567"></TextField>
                    <Button disabled={me?.role === 'admin'} onClick={sendTest} variant="contained">Send</Button>
                </Box>
            </Box>

            <Modal open={isOpenModalEdit} onClose={() => setIsOpenModalEdit(false)}>
                <ModalEditTemplate close={() => { setIsOpenModalEdit(false) }}></ModalEditTemplate>
            </Modal>
        </Box>
    )
}
