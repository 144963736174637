import inMemoryJWT from "../services/inMemoryJWT"
import axios, {AxiosRequestConfig, AxiosResponse} from "axios";

export class Request {
    //static url = 'http://localhost:8080/api/'

    // static url = process.env.REACT_APP_API_URL || 'http://localhost:8080/api/';

    //static url = 'https://sms-be-k20z.onrender.com/api/'

    // static async request(url: string, options: any = {}) {
    //     const token = inMemoryJWT.getToken() || ''
    //
    //     const response: Response = await fetch(`${Request.url}${url}`,
    //         {
    //             headers: {
    //                 "Content-Type": "application/json",
    //                 "Authorization": `Bearer ${token}`
    //               },
    //               credentials: "include",
    //             ...options,
    //         }
    //     )
    //
    //     if(response.status === 401) {
    //         let new_token = null
    //         await fetch(`${Request.url}auth/refresh`,
    //             {
    //                 headers: { "Content-Type": "application/json" },
    //                 credentials: "include",
    //                 method: 'POST'
    //             }
    //         ).then(async (res) => {
    //             const data = await res.json()
    //             console.log(data)
    //             inMemoryJWT.setToken(data.accessToken, data.accessTokenExpiration)
    //             new_token = data.accessToken
    //         }).catch(err => {
    //             inMemoryJWT.setToken(null, null)
    //         })
    //
    //         const response: Response = await fetch(`${Request.url}${url}`,
    //             {
    //                 headers: {
    //                     "Content-Type": "application/json",
    //                     "Authorization": `Bearer ${new_token}`
    //                 },
    //                 credentials: "include",
    //                 ...options,
    //             }
    //         )
    //
    //         if(!response.ok) {
    //             const { error } = await response.json()
    //             throw error
    //         }
    //
    //         const data = await response.json()
    //         return data
    //     }
    //
    //     if(!response.ok) {
    //         const { error } = await response.json()
    //         throw error
    //     }
    //
    //     const data = await response.json()
    //     return data
    // }

    // static async get(url: string) {
    //     const res = await Request.request(url)
    //     return res
    // }

    // static async post(url: string, body: any = {}) {
    //     const res = await Request.request(url, { body: JSON.stringify(body), method: 'POST', })
    //     return res
    // }

    // static async delete(url: string) {
    //     const res = await Request.request(url, { method: 'DELETE', })
    //     return res
    // }


    static url = process.env.REACT_APP_API_URL || 'http://localhost:8080/api/';

    static async request(url: string, options: AxiosRequestConfig = {}) {
        const token = inMemoryJWT.getToken() || '';

        const config: AxiosRequestConfig = {
            url: `${Request.url}${url}`,
            headers: {
                "Content-Type": "application/json",
                ...(token && { "Authorization": `Bearer ${token}` }),
            },
            withCredentials: true,
            ...options
        };

        try {
            const response: AxiosResponse = await axios(config);
            return response.data;
        } catch (error: any) {
            if (error.response && error.response.status === 401) {
                return this.handleTokenRefresh(config);
            }


            console.error('Request error', {
                url,
                method: config.method,
                headers: config.headers,
                data: config.data,
                error: error.message,
            });

            throw error.response?.data?.error || error.message;
        }
    }

    static async handleTokenRefresh(config: AxiosRequestConfig) {
        let new_token = null;
        try {
            const refreshResponse: AxiosResponse = await axios.post(`${Request.url}auth/refresh`, {}, {
                headers: { "Content-Type": "application/json" },
                withCredentials: true
            });
            const data = refreshResponse.data;
            inMemoryJWT.setToken(data.accessToken, data.accessTokenExpiration);
            new_token = data.accessToken;

            config.headers!["Authorization"] = `Bearer ${new_token}`;
            const retryResponse: AxiosResponse = await axios(config);
            return retryResponse.data;
        } catch (refreshError: any) {
            inMemoryJWT.setToken(null, null);
            console.error('Token refresh failed:', refreshError.message);
            throw refreshError;
        }
    }


    static async get(url: string) {
        return await Request.request(url, { method: 'GET' });
    }

    static async post(url: string, body: any = {}) {
        return await Request.request(url, { method: 'POST', data: body });
    }

    static async delete(url: string) {
        return await Request.request(url, { method: 'DELETE' });
    }
}
