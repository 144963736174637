import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {Box, Button, CircularProgress, Menu, MenuItem, TextField, Typography} from "@mui/material";
import { useEffect, useRef, useState } from "react";
import EmojiPicker from 'emoji-picker-react'
import './index.scss'
import moment from "moment";
import { Colors } from "../../../types";
import { useTheme } from "../../../services/theme";
import { useDispatch, useSelector } from "../../../redux/store";
import { IChat, ISms, sendChatSms } from "../../../redux/slices/chat";
import responses, { getResponses } from '../../../redux/slices/responses';

export const Chat = ({ chat }: { chat: IChat | null }) => {
    const { isLoading, isLoadChat } = useSelector(store => store.chat)
    const [sms, setSms] = useState('')
    const { responses } = useSelector(store => store.responses)
    const messagesEndRef = useRef<null | HTMLDivElement>(null)
    const dispatch = useDispatch()

    const [anchorEmoji, setAnchorEmoji] = useState<null | HTMLElement>(null)
    const openE = Boolean(anchorEmoji);
    const handleClickE = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation()
        setAnchorEmoji(event.currentTarget);
    };
    const handleCloseE = () => {
        setAnchorEmoji(null);
    };

    const [anchorReplies, setAnchorReplies] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorReplies);
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorReplies(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorReplies(null);
    };

    const scrollToBottom = () => {
      messagesEndRef.current?.scrollIntoView({ block: 'end' })
    }
  
    useEffect(() => {
      scrollToBottom()
    }, );

    const sendSms = () => {
        if(!sms) return
        if(chat) dispatch(sendChatSms({ id: chat.id, payload: { sms, contact_name: chat.contact_name } }))
    }

    const theme = useTheme()

    if(isLoadChat || !chat) return <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '50vh' }}><CircularProgress></CircularProgress></Box>

    let formattedNumber = chat.number.length === 10 && !chat.number.startsWith('+')
        ? `+1${chat.number}`
        : chat.number.startsWith('+')
            ? chat.number
            : `+${chat.number}`;

    let formattedSendingNumber = chat.sending_number.length === 10 && !chat.sending_number.startsWith('+')
        ? `+1${chat.sending_number}`
        : chat.sending_number.startsWith('+')
            ? chat.sending_number
            : `+${chat.sending_number}`;

    return (
        <div className="chat">
            {/*{chat.contact_name}*/}
            <div className="chat__title" >
                {formattedNumber.length === 10
                    ? <Typography variant="subtitle2">
                        {formattedNumber.slice(0, 3)} {formattedNumber.slice(3, 6)} {formattedNumber.slice(6, 10)}
                    </Typography>
                    : <Typography variant="subtitle2">
                        {formattedNumber.slice(0, 2)} {formattedNumber.slice(2, 5)} {formattedNumber.slice(5, 8)} {formattedNumber.slice(8, 12)}
                    </Typography>
                }
                {formattedSendingNumber.length === 10
                    ? <Typography variant="caption">Sending:
                        {formattedSendingNumber.slice(0, 3)} {formattedSendingNumber.slice(3, 6)} {formattedSendingNumber.slice(6, 10)}
                    </Typography>
                    : <Typography variant="caption">Sending:
                        {formattedSendingNumber.slice(0, 2)} {formattedSendingNumber.slice(2, 5)} {formattedSendingNumber.slice(5, 8)} {formattedSendingNumber.slice(8, 12)}
                    </Typography>
                }
            </div>
            <div className="chat__messages">

                {
                    chat.messages?.map((sms, n) => <SmsBlock key={sms.id} isFirst={n === 0} sms={sms} prevSmsTime={chat.messages ? chat.messages[n - 1]?.createdAt : null}></SmsBlock>)
                }
              
                <div ref={messagesEndRef}></div>
            </div>

            <div className="chat__bottom">

                <Button style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minWidth: 20 }} onClick={handleClickE}>
                    <img 
                        style={{ width: 20, height: 20}} 
                        src='https://cdn.jsdelivr.net/npm/emoji-datasource-apple/img/apple/64/1f601.png'
                        
                    ></img>
                </Button>
                <Menu
                    id="basic-menu"
                    anchorEl={anchorEmoji}
                    open={openE}
                    className='send_menu'
                    onClose={handleCloseE}
                    MenuListProps={{
                    "aria-labelledby": "basic-button",
                    }}
                >
                    <EmojiPicker 
                        open={!!anchorEmoji} 
                        onEmojiClick={(e) => setSms(prev => prev + e.emoji)}
                    ></EmojiPicker>
                </Menu>
                
                <div className="summary_messages_bottom_line">
                    <Button
                        id="basic-button"
                        aria-controls={open ? "basic-menu" : undefined}
                        aria-haspopup="true"
                        aria-expanded={open ? "true" : undefined}
                        onClick={handleClick}
                        sx={{
                            height: '100%',
                            zIndex: 2,
                            padding: 0,
                            minWidth: '30px'
                        }}
                    >
                        <FontAwesomeIcon icon={"fa-solid fa-reply".split(' ') as any}></FontAwesomeIcon>
                    </Button>
                    <Menu
                        id="basic-menu"
                        anchorEl={anchorReplies}
                        open={open}
                        className='send_menu'
                        onClose={handleClose}
                    >
                       { responses.length ? responses.map(response =>
                            <MenuItem
                                onClick={() => {
                                  setSms(response.text);
                                    handleClose();
                                }}
                            >   
                                { response.name }
                            </MenuItem>
                        ) : 'There no quick responses'}
                    </Menu>
                </div>

                <TextField value={sms} onChange={(e) => setSms(e.target.value)} placeholder="Type your message" fullWidth style={{ marginRight: 4, marginLeft: 4 }}></TextField>
                
                
                <Button onClick={sendSms} style={{ minWidth: '40px' }}>
                <FontAwesomeIcon icon={"fa-solid fa-paper-plane".split(' ') as any}></FontAwesomeIcon>
                    {/* Send */}
                </Button>
            </div>
        </div>
    )
}

const SmsBlock = ({ sms, prevSmsTime, isFirst }: { sms: ISms, prevSmsTime: string | null, isFirst: boolean }) => {
    const theme = useTheme()

    const endOfDay = moment(sms.createdAt).startOf('day').isAfter(moment(prevSmsTime).startOf('day'))

    return (
        <>
           {
            (endOfDay || isFirst) &&  <div style={{ display: 'flex', justifyContent: 'center', marginTop: 12 }}>
                <span style={{ fontWeight: '200' }}>{moment(sms.createdAt).format('MM/DD dddd')}</span>
            </div>
           }

            <div
                key={sms.id} 
                style={{ backgroundColor: theme === 'light' ? Colors.MAIN_WHITE : Colors.MAIN_DARK }} 
                className={sms.direction === 'incoming' ? "chat__message" : "chat__message-my"}
            >
                <span>{sms.text}</span>
                <span style={{ marginTop: 4, fontSize: 12, alignSelf: 'flex-end' }}>
                    {moment(sms.createdAt).format('h:mm a')}
                </span>
            </div>
        </>
    )
}
