import React, { useCallback, useEffect, useState } from "react";
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell,
    TableBody, Paper, Container, Button, Stack, Switch, Select, MenuItem } from "@mui/material";
import { useDispatch, useSelector } from "../../../../redux/store";
import { IUser, clearUsers, getManagerUsers, getUsers, updateUser } from "../../../../redux/slices/user";
import { ModalAddServer, ModalAddStaff, ModalCreateUser, ModalDeleteUser, ModalEditUser } from "./utils";
import { useAuth } from "../../../../services/authContext";

export const Users = () => {
    const { me, SignInAsUser } = useAuth();
    const [openModal, setOpenModal] = useState(false);
    const [openModalUpdate, setOpenModalUpdate] = useState<IUser | null>(null);
    const [openModalAddStaff, setOpenModalAddStaff] = useState('');
    const [openModalAddServer, setOpenModalAddServer] = useState('');
    const [isDeleteUser, setIsDeleteUser] = useState<{id: string, firstname: string, lastname: string} | null>(null);
    const [horizontalScroll, setHorizontalScroll] = useState(0);
    const [expandedManagers, setExpandedManagers] = useState<string[]>([]);
    const { users, manager_users } = useSelector(store => store.user);
    const dispatch = useDispatch();

    useEffect(() => {
        if(me?.role === 'admin') {
            dispatch(getUsers());
        }
        if(me?.role === 'manager') {
            dispatch(getManagerUsers({ user_id: me?.id }));
        }

        return () => { dispatch(clearUsers()) };
    }, [dispatch, me]);

    const handleUpdate = (id: string, payload: any) => {
        dispatch(updateUser({ id, payload }));
    };

    const handleScroll = useCallback((event: any) => {
        const { scrollTop, scrollHeight, clientHeight, scrollLeft } = event.target;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight - 0.5;

        if(scrollLeft !== horizontalScroll) {
            setHorizontalScroll(scrollLeft);
            return;
        }

        if (isAtBottom) {
            dispatch(getUsers());
        }
    }, [horizontalScroll, dispatch]);

    const logginAsUser = (id: string) => {
        SignInAsUser(id, me?.id);
    };

    const handleExpandClick = (managerId: string) => {
        setExpandedManagers((prev) => {
            if (prev.includes(managerId)) {
                return prev.filter((id) => id !== managerId);
            } else {
                return [...prev, managerId];
            }
        });
    };

    const getStaffForManager = (managerId: string) => {
        const dataSource = me?.role === 'admin' ? users : manager_users;
        return dataSource.filter((user) => user.manager_id === managerId && user.role === 'staff');
    };

    return (
        <div>
            <Box sx={{display: 'flex', alignItems: 'center'}}>
                <Typography variant="h5">Workspace</Typography><span> /users</span>
            </Box>

            <Container sx={{display: 'flex', justifyContent: 'space-between', marginTop: 4}}>
                <Box>
                    <Typography variant="h6">Workspace users</Typography>
                    <Typography sx={{fontSize: 12}} variant="subtitle2">Manage your workspace users here</Typography>
                </Box>
                <Stack direction={{xs: 'column', sm: 'row'}} spacing={2}>
                    <Button onClick={() => setOpenModal(true)} size="small" variant="contained">Create User</Button>
                </Stack>
            </Container>

            <TableContainer onScroll={handleScroll} style={{marginTop: 30, maxHeight: 'calc(100vh - 300px)'}}
                            component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{minWidth: 650}}>
                    <TableHead>
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell>EMAIL</TableCell>
                            <TableCell>NAME</TableCell>
                            <TableCell>ROLE</TableCell>
                            <TableCell>SENDING SERVERS ASSIGNED</TableCell>
                            <TableCell>STATUS</TableCell>
                            <TableCell></TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {(
                            ((me?.role === 'admin') ? users : manager_users)
                                .filter(user => user.role === 'manager')
                        ).map((user) => (
                            <React.Fragment key={user.id}>
                                <TableRow>
                                    <TableCell onClick={() => handleExpandClick(user.id)} style={{ cursor: 'pointer' }}>
                                        {expandedManagers.includes(user.id) ? '-' : '+'}
                                    </TableCell>

                                    <TableCell>{user.email}</TableCell>
                                    <TableCell>{`${user.firstname} ${user.lastname}`}</TableCell>
                                    <TableCell>
                                        <Select
                                            value={user.role}
                                            style={{marginTop: 10, width: '100%'}}
                                            onChange={(data) => {
                                                handleUpdate(user.id, {role: data.target.value});
                                            }}
                                            disabled={me?.role !== 'admin'}
                                        >
                                            <MenuItem value={'manager'}>Manager</MenuItem>
                                        </Select>
                                    </TableCell>
                                    <TableCell>
                                        {[...(user?.servers || []), ...(user?.groups || [])].map(it => it.name).join(', ')}
                                    </TableCell>
                                    <TableCell>
                                        <Switch
                                            disabled={me?.role !== 'admin'}
                                            checked={user.status}
                                            onChange={() => handleUpdate(user.id, {status: !user.status})}
                                        />
                                    </TableCell>
                                    <TableCell>
                                        <Stack direction={{xs: 'column', sm: 'row'}} gap={1}>
                                            {me?.role === 'admin' && (
                                                <Button onClick={() => setOpenModalAddServer(user.id)} variant="contained">
                                                    Add Sending Server
                                                </Button>
                                            )}
                                            <Button onClick={() => setOpenModalAddStaff(user.id)} variant="contained">
                                                Add Staff
                                            </Button>
                                            {me?.role !== 'staff' && me?.id !== user.id && (
                                                <Button onClick={() => logginAsUser(user.id)} variant="contained">
                                                    Login as User
                                                </Button>
                                            )}
                                            {me?.role === 'admin' && (
                                                <Button variant="contained" color="error"
                                                        onClick={() => setIsDeleteUser({...user})}>
                                                    Delete
                                                </Button>
                                            )}
                                            <Button onClick={() => setOpenModalUpdate(user)}>
                                                Edit
                                            </Button>
                                        </Stack>
                                    </TableCell>
                                </TableRow>

                                {expandedManagers.includes(user.id) && getStaffForManager(user.id).map((staffUser) => (
                                    <TableRow key={staffUser.id} style={{ backgroundColor: '#f9f9f9' }}>
                                        <TableCell></TableCell>
                                        <TableCell>{staffUser.email}</TableCell>
                                        <TableCell>{`${staffUser.firstname} ${staffUser.lastname}`}</TableCell>
                                        <TableCell>
                                            <Select
                                                value={staffUser.role}
                                                style={{marginTop: 10, width: '100%'}}
                                                onChange={(data) => {
                                                    handleUpdate(staffUser.id, {role: data.target.value});
                                                }}
                                                disabled={me?.role !== 'admin'}
                                            >
                                                <MenuItem value={'staff'}>Staff</MenuItem>
                                            </Select>
                                        </TableCell>
                                        <TableCell>
                                            {[...(staffUser?.servers || []), ...(staffUser?.groups || [])].map(it => it.name).join(', ')}
                                        </TableCell>
                                        <TableCell>
                                            <Switch
                                                disabled={me?.role !== 'admin'}
                                                checked={staffUser.status}
                                                onChange={() => handleUpdate(staffUser.id, {status: !staffUser.status})}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Stack direction={{xs: 'column', sm: 'row'}} gap={1}>
                                                {me?.role !== 'staff' && me?.id !== staffUser.id && (
                                                    <Button onClick={() => logginAsUser(staffUser.id)} variant="contained">
                                                        Login as User
                                                    </Button>
                                                )}
                                                {me?.role === 'admin' && (
                                                    <Button variant="contained" color="error"
                                                            onClick={() => setIsDeleteUser({...staffUser})}>
                                                        Delete
                                                    </Button>
                                                )}
                                                <Button onClick={() => setOpenModalUpdate(staffUser)}>
                                                    Edit
                                                </Button>
                                            </Stack>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </React.Fragment>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            {openModal && <ModalCreateUser open={openModal} close={() => setOpenModal(false)} />}
            {openModalUpdate && (
                <ModalEditUser open={openModalUpdate} close={() => setOpenModalUpdate(null)} />
            )}
            {openModalAddStaff && (
                <ModalAddStaff user_id={openModalAddStaff} close={() => setOpenModalAddStaff('')} />
            )}
            {openModalAddServer && (
                <ModalAddServer user_id={openModalAddServer} close={() => setOpenModalAddServer('')} />
            )}
            {isDeleteUser && (
                <ModalDeleteUser open={isDeleteUser} close={() => setIsDeleteUser(null)} />
            )}
        </div>
    );
};
