import React, { useEffect, useState } from "react";
import {
    Box,
    Container,
    FormControl,
    Grid,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
    useMediaQuery,
    CircularProgress,
    TextField,
} from "@mui/material";
import Loader from "../../../../components/Loader/Loader";
import { useDispatch, useSelector } from "../../../../redux/store";
import { useAuth } from "../../../../services/authContext";
import { useTheme } from "@mui/material/styles";
import { deviceInfo, deviceMessage, devicesList } from "../../../../redux/sim/sim.request";

export const DashboardSim = () => {
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.up("md"));

    const dispatch = useDispatch();
    const { me } = useAuth();

    const { deviceList, deviceInfoByPort } = useSelector((store) => store.sim);
    const [selectedSimBox, setSelectedSimBox] = useState("");
    const [filterPort, setFilterPort] = useState("");
    const [loadingSimBoxes, setLoadingSimBoxes] = useState(true);
    const [loadingMessages, setLoadingMessages] = useState(false);

    useEffect(() => {
        setLoadingSimBoxes(true);
        dispatch(devicesList())
            .unwrap()
            .then(() => setLoadingSimBoxes(false))
            .catch(() => setLoadingSimBoxes(false));
    }, []);

    useEffect(() => {
        if (selectedSimBox) {
            setLoadingMessages(true);
            Promise.all([
                dispatch(deviceMessage({ dev: selectedSimBox })).unwrap(),
                dispatch(deviceInfo({ dev: selectedSimBox })).unwrap(),
            ])
                .then(() => {
                    setLoadingMessages(false);
                })
                .catch(() => {
                    setLoadingMessages(false);
                });
        }
    }, [selectedSimBox]);

    const handleSimBoxClick = (deviceKey: string) => {
        setSelectedSimBox(deviceKey);
    };

    return (
        <Container maxWidth="lg">
            <Box my={4}>
                {loadingSimBoxes ? (
                    <Loader />
                ) : (
                    <Grid container spacing={2}>
                        {Object.keys(deviceList).map((deviceKey) => {
                            const simBox = deviceList[deviceKey];
                            return (
                                <Grid item xs={12} sm={6} md={4} lg={3} key={deviceKey}>
                                    <Paper
                                        elevation={3}
                                        sx={{
                                            padding: 2,
                                            cursor: "pointer",
                                            border:
                                                selectedSimBox === deviceKey
                                                    ? `2px solid ${theme.palette.primary.main}`
                                                    : "none",
                                        }}
                                        onClick={() => handleSimBoxClick(deviceKey)}
                                    >
                                        <Typography variant="h6">Device {deviceKey}</Typography>
                                        <Typography>Ports: {simBox.count_ports}</Typography>
                                        <Typography>Sent Messages: {simBox.count_send}</Typography>
                                        <Typography>Blocked Messages: {simBox.count_block}</Typography>
                                    </Paper>
                                </Grid>
                            );
                        })}
                    </Grid>
                )}

                {selectedSimBox && (
                    <Box mt={4}>
                        <Typography variant="h5" gutterBottom>
                            Data for {selectedSimBox}
                        </Typography>

                        <Box mb={2} display="flex" alignItems="center">
                            <FormControl variant="outlined" sx={{ minWidth: 200, mr: 2 }}>
                                <TextField
                                    label="Filter by port"
                                    variant="outlined"
                                    value={filterPort}
                                    onChange={(e) => setFilterPort(e.target.value)}
                                />
                            </FormControl>
                        </Box>

                        {loadingMessages ? (
                            <CircularProgress />
                        ) : (
                            <TableContainer component={Paper}>
                                <Table>
                                    <TableHead>
                                        <TableRow>
                                            <TableCell>Port</TableCell>
                                            <TableCell>Sending Interval (sec)</TableCell>
                                            <TableCell>Max Msgs/Hour</TableCell>
                                            <TableCell>Max Msgs/Day</TableCell>
                                            <TableCell>Interval Days</TableCell>
                                            <TableCell>Block Days</TableCell>
                                            <TableCell>Sent Messages</TableCell>
                                            <TableCell>Blocked Messages</TableCell>
                                            <TableCell>Status</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {deviceInfoByPort
                                            .filter((device) => device.port.includes(filterPort))
                                            .map((device, index) => {
                                                // Determine status and color based on control flags
                                                let statusText = 'Running';
                                                let statusColor = 'green';

                                                if (device.control.stop) {
                                                    statusText = 'Stopped';
                                                    statusColor = 'red';
                                                } else if (device.control.restart) {
                                                    statusText = 'Restarting';
                                                    statusColor = 'yellow';
                                                } else if (device.control.analysis) {
                                                    statusText = 'Analyzing';
                                                    statusColor = 'blue';
                                                }

                                                return (
                                                    <TableRow key={index}>
                                                        <TableCell>{device.port}</TableCell>
                                                        <TableCell>{device.params[0]}</TableCell>
                                                        <TableCell>{device.params[1]}</TableCell>
                                                        <TableCell>{device.params[2]}</TableCell>
                                                        <TableCell>
                                                            {device.params[3]
                                                                ? device.params[3].join(', ')
                                                                : 'N/A'}
                                                        </TableCell>
                                                        <TableCell>
                                                            {device.params[4] !== null
                                                                ? device.params[4]
                                                                : 'N/A'}
                                                        </TableCell>
                                                        <TableCell>{device.count_send}</TableCell>
                                                        <TableCell>{device.count_block}</TableCell>
                                                        <TableCell>
                                <span style={{ color: statusColor }}>
                                    {statusText}
                                </span>
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            })}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        )}
                    </Box>
                )}
            </Box>
        </Container>
    );
};
