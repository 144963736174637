import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import { Request } from "../request";
import { toast } from "react-toastify";
import { IServer } from "./server";
import { IGroup } from "./groups";

export interface IUser {
  id: string;
  firstname: string;
  lastname: string;
  email: string;
  phone: string;
  role: string;
  manager_id: string| null
  status: boolean
  createdAt: string;
  servers: IServer[]
  groups: IGroup[]
}

type State = {
  user: IUser | null;
  users: IUser[];
  manager_users: IUser[];
  staff: IUser[];
  limit: number;
  offset: number;
  isLoading: boolean;
};

const initialState: State = {
  user: null,
  users: [],
  manager_users: [],
  staff: [],
  limit: 8,
  offset: 0,
  isLoading: false,
};


export const getUser = createAsyncThunk(
  "/user/get",
  async (id: string, { rejectWithValue }) => {
    try {
        const response = await Request.get(`users/${id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getUsers = createAsyncThunk(
  "/user/getAll",
  async (_, { getState, rejectWithValue }: any) => {
    try {
        const { offset, limit } = getState().user as State
        const response = await Request.get(`users?offset=${offset}&limit=${limit}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const createUser = createAsyncThunk(
  "/user/create",
  async (payload: any, { rejectWithValue }) => {
    try {
        const response = await Request.post('users', payload)
        toast('created', { type: 'success' })
        return response
    } catch (error: any) {
      toast('some error', { type: 'error' })
      return rejectWithValue(error);
    }
  }
);

export const updateUser = createAsyncThunk(
  "/user/update",
  async ({ id, payload }: any, { rejectWithValue }) => {
    try {
        const response = await Request.post(`users/${id}`, payload)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getStaff = createAsyncThunk(
  "/user/staff",
  async (id: string, { rejectWithValue }) => {
    try {
        const response = await Request.get(`users/staff/${id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const addStaff = createAsyncThunk(
  "/user/addStaff",
  async ({ id, payload }: { id: string, payload: any }, { rejectWithValue }) => {
    try {
        const response = await Request.post(`users/staff/${id}`, payload)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const addUserServer = createAsyncThunk(
  "/user/addServer",
  async ({ id, user_id, type }: { id: string, user_id: string, type: string }, { rejectWithValue }) => {
    try {
        const response = await Request.get(`users/server/${id}/${user_id}?type=${type}`)
        return response
    } catch (error: any) {
      toast('Server can be added to only one user', { type: 'error' })
      return rejectWithValue(error);
    }
  }
);

export const removeUserServer = createAsyncThunk(
  "/user/addStaff",
  async ({ id, user_id, type }: { id: string, user_id: string, type: string }, { rejectWithValue }) => {
    try {
        const response = await Request.delete(`users/server/${id}/${user_id}?type=${type}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const removeManagerr = createAsyncThunk(
  "/user/manager",
  async ({ id, user_id, type }: { id: string, user_id: string, type: string }, { rejectWithValue }) => {
    try {
        const response = await Request.delete(`users/manager/${id}/${user_id}?type=${type}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getManagerUsers = createAsyncThunk(
  "/user/chat/:id",
  async ({ user_id }: { user_id: string }, { rejectWithValue }) => {
    try {
        const response = await Request.get(`users/manager/${user_id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const deleteUser = createAsyncThunk(
  "/user/delete",
  async ({ user_id, assigned_id }: { user_id: string, assigned_id: string }, { rejectWithValue }) => {
    try {
        const response = await Request.delete(`users/${user_id}/${assigned_id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);


const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    clearUser: (state) => {
        state.user = null
    },
    clearUsers: (state) => {
        state.users = []
        state.offset = 0
    },
    clearStaff: (state) => {
      state.staff = []
    }
  },
  extraReducers: (builder) => {
    builder
      // get
      .addMatcher(
        (action) => action.type === getUser.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getUser.fulfilled.type,
        (state, action: PayloadAction<{ user: IUser }>) => {
          state.isLoading = false;
          state.user = action.payload.user
        }
      )
      .addMatcher(
        (action) => action.type === getUser.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // get all
      .addMatcher(
        (action) => action.type === getUsers.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getUsers.fulfilled.type,
        (state, action: PayloadAction<{users: IUser[]}>) => {
            const currentUsers = current(state.users);
            const newUsers = action.payload.users;

            const usersMap = new Map();

            [...currentUsers, ...newUsers].forEach((user) => {
                usersMap.set(user.id, user);
            });

            state.users = Array.from(usersMap.values());
            // console.log(state.users)
            // state.offset = current(state).offset + action.payload.users.length || current(state).limit
            const totalLength = current(state).offset + action.payload.users.length + 1;
            let offset = current(state).offset + action.payload.users.length + 1;
            if (offset > totalLength)
                offset = totalLength;

            state.offset = offset || current(state).limit

            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getUsers.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // create
      .addMatcher(
        (action) => action.type === createUser.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === createUser.fulfilled.type,
        (state, action: PayloadAction<{user: IUser}>) => {
            state.users = [action.payload.user, ...current(state.users)]
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === createUser.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // update
      .addMatcher(
        (action) => action.type === updateUser.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === updateUser.fulfilled.type,
        (state, action: PayloadAction<{user: IUser}>) => {
            state.users = current(state).users.map(user => user.id === action.payload.user.id ? action.payload.user : user)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === updateUser.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // staff
      .addMatcher(
        (action) => action.type === getStaff.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getStaff.fulfilled.type,
        (state, action: PayloadAction<{staff: IUser[]}>) => {
            state.staff = [...action.payload.staff]
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getStaff.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // add server
      .addMatcher(
        (action) => action.type === addUserServer.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === addUserServer.fulfilled.type,
        (state, action: PayloadAction<{user: IUser}>) => {
          if(state.user) {
            state.user = action.payload.user
            state.users = current(state).users.map(user => user.id === action.payload.user.id ? action.payload.user : user)
          }
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === addUserServer.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // remove server
      .addMatcher(
        (action) => action.type === removeUserServer.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === removeUserServer.fulfilled.type,
        (state, action: PayloadAction<{user: IUser}>) => {
          if(state.user) {
            state.user = action.payload.user
            state.users = current(state).users.map(user => user.id === action.payload.user.id ? action.payload.user : user)
          }
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === removeUserServer.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // get manager users
      .addMatcher(
        (action) => action.type === getManagerUsers.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getManagerUsers.fulfilled.type,
        (state, action: PayloadAction<{users: IUser[]}>) => { 
          state.manager_users = action.payload.users
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getManagerUsers.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // delete users
      .addMatcher(
        (action) => action.type === deleteUser.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === deleteUser.fulfilled.type,
        (state, action: PayloadAction<{id: string}>) => { 
          state.users = current(state.users).filter(user => user.id !== action.payload.id)
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === deleteUser.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
  },
});

export const { clearUser, clearUsers, clearStaff } = userSlice.actions;

export default userSlice.reducer;
