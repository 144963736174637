import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, Paper, Container, Button, Stack, Modal, InputLabel, TextField, Divider, Select, FormControl, MenuItem, SelectChangeEvent, Switch, Grid } from "@mui/material"
import { useDispatch, useSelector } from "../../../../redux/store";
import { IUser, clearUsers, createUser, getUsers, updateUser } from "../../../../redux/slices/user";
import { Colors } from "../../../../types";
import { useTheme } from "../../../../services/theme";

export const SendingServersCustom = () => {
    const [openModal, setOpenModal] = useState(false);
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('')
    const [email, setEmail] = useState('')
    const [phone, setPhone] = useState('')
    const [role, setRole] = useState('')
    const [horizontalScroll, setHorizontalScroll] = useState(0)
   
    const { users } = useSelector(store => store.user)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUsers())

        return () => { dispatch(clearUsers()) }
    }, [])

  
    function createData(
        name: string,
        calories: number,
        fat: number,
        carbs: number,
        protein: number,
      ) {
        return { name, calories, fat, carbs, protein };
      }

    const theme = useTheme()

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: '2px' }}>
                <Typography variant="h5">Workspace</Typography><span> /sending servers/ custom</span>
            </Box>
                
            <Box sx={{ 
                    backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE, 
                    padding: 2, borderRadius: 2, marginTop: 2, display: 'flex', flexDirection: 'column', gap: 1
                }}
            >
                <Typography variant="subtitle1" marginBottom={1}>Create Your Own Server</Typography>

                <TextField placeholder="Name*" fullWidth></TextField>
                <TextField placeholder="Base URL*" fullWidth></TextField>
                <TextField placeholder="Success Keywords*" fullWidth></TextField>
                <TextField placeholder="HTTP Reques Method*" fullWidth></TextField>
                <FormControl style={{ marginTop: 10, width: '100%', }}>
                    <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">HTTP Reques Method*</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="HTTP Reques Method*"
                    >
                        <MenuItem value={'get'}>GET</MenuItem>
                        <MenuItem value={'post'}>POST</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ marginTop: 10, width: '100%', }}>
                    <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Enable json encoded post</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Enable json encoded post"
                    >
                        <MenuItem value={'yes'}>YES</MenuItem>
                        <MenuItem value={'no'}>NO</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ marginTop: 10, width: '100%', }}>
                    <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Content Type</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Content Type"
                    >
                        <MenuItem value={'none'}>NONE</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ marginTop: 10, width: '100%', }}>
                    <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Content Type Accept</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Content Type Accept"
                    >
                        <MenuItem value={'none'}>NONE</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ marginTop: 10, width: '100%', }}>
                    <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Character Encoding</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Character Encoding"
                    >
                        <MenuItem value={'none'}>NONE</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ marginTop: 10, width: '100%', }}>
                    <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Ignore SSL certeficate verification</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Ignore SSL certeficate verification"
                    >
                        <MenuItem value={'yes'}>YES</MenuItem>
                        <MenuItem value={'no'}>NO</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ marginTop: 10, width: '100%', }}>
                    <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Authorization*</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Authorization*"
                    >
                        <MenuItem value={'yes'}>YES</MenuItem>
                        <MenuItem value={'no'}>NO</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ marginTop: 10, width: '100%', }}>
                    <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Plain*</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Plain*"
                    >
                        <MenuItem value={'yes'}>YES</MenuItem>
                        <MenuItem value={'no'}>NO</MenuItem>
                    </Select>
                </FormControl>
                <FormControl style={{ marginTop: 10, width: '100%', }}>
                    <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Schedule*</InputLabel>
                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        label="Schedule*"
                    >
                        <MenuItem value={'yes'}>YES</MenuItem>
                        <MenuItem value={'no'}>NO</MenuItem>
                    </Select>
                </FormControl>
            </Box>
            
            <Box sx={{ 
                    backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE, 
                    padding: 2, borderRadius: 2, marginTop: 2, display: 'flex', flexDirection: 'column', gap: 1
                }}
            >
                <Typography variant="subtitle1" marginBottom={1}>Sending limit</Typography>
                <Typography variant="caption" marginBottom={1}>
                    The configuration setting below allows you to set a limit on sms sending speed. For example, to limit
                    sending speed to <span style={{ color: 'red' }}>500 sms every 5 minutes</span>, you can set 
                    <span style={{ color: 'red' }}> Sending limit = 500, Time value = 5, and Time unit = minute</span> accordingly.
                    Also set <span style={{ color: 'red' }}>amount</span> of <span style={{ color: 'red' }}>several messages</span> with a <span style={{ color: 'red' }}>single HTTP request</span>.
                </Typography>

                <TextField placeholder="Sending limit" fullWidth></TextField>
                <TextField placeholder="Time Base" fullWidth></TextField>
                <TextField placeholder="Time Unit" fullWidth></TextField>
            </Box>
            
            <Box sx={{ 
                    backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE, 
                    padding: 2, borderRadius: 2, marginTop: 2, display: 'flex', flexDirection: 'column', gap: 1,
                    overflowX: 'auto'
                }}
            >
                <Typography variant="subtitle1" marginBottom={1}>Query Parameters</Typography>

                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 800 }}>
                    <Typography style={{ width: '20%' }}></Typography>
                    <Typography style={{ width: '30%' }}>PARAMETER</Typography>
                    <Typography style={{ width: '30%' }}>VALUE</Typography>
                    <Typography style={{ width: '20%' }}>ADD ON URL</Typography>
                </Box>
                
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 800  }}>
                    <Typography style={{ width: '20%' }}>Username / API key</Typography>
                    <TextField style={{ width: '30%' }}></TextField>
                    <TextField style={{ width: '30%' }}></TextField>
                    <div style={{ width: '20%' }}></div>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 800  }}>
                    <Typography style={{ width: '20%' }}>Password</Typography>
                    <TextField style={{ width: '30%' }}></TextField>
                    <TextField style={{ width: '30%' }}></TextField>
                    <FormControl style={{ width: '20%', }}>
                        <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Set Blank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Set Blank"
                        >
                            <MenuItem value={'yes'}>YES</MenuItem>
                            <MenuItem value={'no'}>NO</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 800  }}>
                    <Typography style={{ width: '20%' }}>Actions</Typography>
                    <TextField style={{ width: '30%' }}></TextField>
                    <TextField style={{ width: '30%' }}></TextField>
                    <FormControl style={{ width: '20%', }}>
                        <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Set Blank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Set Blank"
                        >
                            <MenuItem value={'yes'}>YES</MenuItem>
                            <MenuItem value={'no'}>NO</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 800  }}>
                    <Typography style={{ width: '20%' }}>Source</Typography>
                    <TextField style={{ width: '30%' }}></TextField>
                    <TextField style={{ width: '30%' }}></TextField>
                    <FormControl style={{ width: '20%', }}>
                        <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Set Blank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Set Blank"
                        >
                            <MenuItem value={'yes'}>YES</MenuItem>
                            <MenuItem value={'no'}>NO</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 800  }}>
                    <Typography style={{ width: '20%' }}>Destination</Typography>
                    <TextField style={{ width: '30%' }}></TextField>
                    <div style={{ width: '50%' }}></div>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 800  }}>
                    <Typography style={{ width: '20%' }}>Message</Typography>
                    <TextField style={{ width: '30%' }}></TextField>
                    <div style={{ width: '50%' }}></div>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 800  }}>
                    <Typography style={{ width: '20%' }}>Unicode</Typography>
                    <TextField style={{ width: '30%' }}></TextField>
                    <TextField style={{ width: '30%' }}></TextField>
                    <FormControl style={{ width: '20%', }}>
                        <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Set Blank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Set Blank"
                        >
                            <MenuItem value={'yes'}>YES</MenuItem>
                            <MenuItem value={'no'}>NO</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 800  }}>
                    <Typography style={{ width: '20%' }}>Type Route</Typography>
                    <TextField style={{ width: '30%' }}></TextField>
                    <TextField style={{ width: '30%' }}></TextField>
                    <FormControl style={{ width: '20%', }}>
                        <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Set Blank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Set Blank"
                        >
                            <MenuItem value={'yes'}>YES</MenuItem>
                            <MenuItem value={'no'}>NO</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 800  }}>
                    <Typography style={{ width: '20%' }}>Language</Typography>
                    <TextField style={{ width: '30%' }}></TextField>
                    <TextField style={{ width: '30%' }}></TextField>
                    <FormControl style={{ width: '20%', }}>
                        <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Set Blank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Set Blank"
                        >
                            <MenuItem value={'yes'}>YES</MenuItem>
                            <MenuItem value={'no'}>NO</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 800  }}>
                    <Typography style={{ width: '20%' }}>Custom Value 1</Typography>
                    <TextField style={{ width: '30%' }}></TextField>
                    <TextField style={{ width: '30%' }}></TextField>
                    <FormControl style={{ width: '20%', }}>
                        <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Set Blank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Set Blank"
                        >
                            <MenuItem value={'yes'}>YES</MenuItem>
                            <MenuItem value={'no'}>NO</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 800  }}>
                    <Typography style={{ width: '20%' }}>Custom Value 2</Typography>
                    <TextField style={{ width: '30%' }}></TextField>
                    <TextField style={{ width: '30%' }}></TextField>
                    <FormControl style={{ width: '20%', }}>
                        <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Set Blank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Set Blank"
                        >
                            <MenuItem value={'yes'}>YES</MenuItem>
                            <MenuItem value={'no'}>NO</MenuItem>
                        </Select>
                    </FormControl>
                </Box>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, minWidth: 800  }}>
                    <Typography style={{ width: '20%' }}>Custom Value 3</Typography>
                    <TextField style={{ width: '30%' }}></TextField>
                    <TextField style={{ width: '30%' }}></TextField>
                    <FormControl style={{ width: '20%', }}>
                        <InputLabel sx={{ color: Colors.MAIN_LIGTH, }} id="demo-simple-select-label">Set Blank</InputLabel>
                        <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            label="Set Blank"
                        >
                            <MenuItem value={'yes'}>YES</MenuItem>
                            <MenuItem value={'no'}>NO</MenuItem>
                        </Select>
                    </FormControl>
                </Box>

                <Button variant="contained" style={{ width: 100, marginTop: 20 }}>Save</Button>
            </Box>
        </div>
    )
}
