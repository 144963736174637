import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import { Request } from "../request";
import { toast } from "react-toastify";
import { IUser } from "./user";

export interface ISms {
    id: string
    chat_id: string
    chat_number: string
    text: string
    direction: 'outgoing' | 'incoming'
    createdAt: string
}

export interface IChat {
    id: string
    number: string
    sending_number: string
    contact_name: string
    manager_id: string
    staff_id: string
    is_top: boolean
    is_trash: boolean

    have_unseen_sms: boolean
    is_answered: boolean
    last_sms_time: string
    createdAt: string;

    messages?: ISms[],
    user: IUser
}

type State = {
  chat: IChat | null;
  chats: IChat[];
  autocomplete: IChat[] | null;
  limit: number;
  offset: number;
  isLoading: boolean;
  isLoadChat: boolean;
};

const initialState: State = {
  chat: null,
  chats: [],
  autocomplete: null,
  limit: 32,
  offset: 0,
  isLoading: false,
  isLoadChat: false,
};


export const getChat = createAsyncThunk(
  "/chats/get",
  async (id: string, { rejectWithValue }) => {
    try {
        const response = await Request.get(`chats/${id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getChats = createAsyncThunk(
  "/chats/getAll",
  async (
    { showTrash, campaignId, showAll, onlyReplied, onlyUnread, sortByOldest }: { 
        showTrash: boolean, campaignId: string, showAll: boolean,
        onlyReplied: boolean, onlyUnread: boolean, sortByOldest: boolean
    },
    { getState, rejectWithValue }: any) => {
    try {
        const { offset, limit } = getState().chat as State
        const response = await Request.get(
          `chats?offset=${0}&limit=${limit}&trash=${showTrash}&all=${showAll}&campaign_id=${campaignId}&replied=${onlyReplied}&unread=${onlyUnread}&oldest=${sortByOldest}`
          )
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const loadMoreChats = createAsyncThunk(
  "/chats/loadmore",
  async ({showTrash, campaignId, showAll, onlyReplied, onlyUnread, sortByOldest }: { showTrash: boolean, campaignId: string, showAll: boolean, onlyReplied: boolean, onlyUnread: boolean, sortByOldest: boolean }, { getState, rejectWithValue }: any) => {
    try {
        const { offset, limit } = getState().chat as State
        const response = await Request.get(
          `chats?offset=${offset}&limit=${limit}&trash=${showTrash}&all=${showAll}&campaign_id=${campaignId}&replied=${onlyReplied}&unread=${onlyUnread}&oldest=${sortByOldest}`
        )
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const updateChat = createAsyncThunk(
  "/chats/update",
  async ({id, payload}: { id: string, payload: any }, { rejectWithValue }: any) => {
    try {
        const response = await Request.post(`chats/${id}`, payload)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const autocompleteChats = createAsyncThunk(
  "/chats/autocomplete",
  async (text: string, { rejectWithValue }: any) => {
    try {
        const response = await Request.get(`chats/autocomplete?text=${text}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const sendChatSms = createAsyncThunk(
  "/chats/send",
  async ({id, payload}: { id: string, payload: any }, { rejectWithValue }: any) => {
    try {
        const response = await Request.post(`chats/send/${id}`, payload)
        return response
    } catch (error: any) {
      toast(error, { type: 'error' })
      return rejectWithValue(error);
    }
  }
);


const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: { 
    clearChat: (state) => {
        state.chat = null
    },
    clearChats: (state) => {
        state.chats = []
        state.offset = 0
    },
    clearAutocompleteChats: (state) => {
        state.autocomplete = null
    },
    onNewSms: (state, action) => {
      const data: ISms = action.payload.sms
      if (!state.chat) return
      const current_chat = current(state.chat)
      if (data.chat_id === current_chat?.id) {
        const messages = current_chat.messages || []
        if (state.chat) {
          state.chat.messages = [...messages, data]
        }
      }
    },
    onNewChat: (state, action) => {
      const new_chat: IChat = action.payload
      let current_chats = [...current(state.chats)]
      let tagged_chats = current_chats.filter(chat => chat.is_top)
      let untagged_chats = current_chats.filter(chat => !chat.is_top)
      if(new_chat.is_top) {
        const chat_index = tagged_chats.findIndex(it => it.id === new_chat.id)
        if (chat_index === -1) {
            state.chats = [new_chat, ...tagged_chats, ...untagged_chats]
        } else {
            tagged_chats.splice(chat_index, 1)
            state.chats = [new_chat, ...tagged_chats, ...untagged_chats]
        }
      } else {
        const chat_index = untagged_chats.findIndex(it => it.id === new_chat.id)
        if (chat_index === -1) {
            state.chats = [...tagged_chats, new_chat, ...untagged_chats]
        } else {
            untagged_chats.splice(chat_index, 1)
            state.chats = [...tagged_chats, new_chat, ...untagged_chats]
        }
      }
    },
    onOpenChat: (state, action) => {
      const chat_id: string = action.payload
      let current_chats = [...current(state.chats)]

      state.chats = current_chats.map(chat => chat.id === chat_id ? { ...chat, have_unseen_sms: false } : chat)
    }
  },
  extraReducers: (builder) => {
    builder
      // get
      .addMatcher(
        (action) => action.type === getChat.pending.type,
        (state) => {
          state.isLoadChat = true;
        }
      )
      .addMatcher(
        (action) => action.type === getChat.fulfilled.type,
        (state, action: PayloadAction<{chat: IChat}>) => {
          state.chat = action.payload.chat
          state.isLoadChat = false;
        }
      )
      .addMatcher(
        (action) => action.type === getChat.rejected.type,
        (state, action: any) => {
          state.isLoadChat = false;
        }
      )
      // get all
      .addMatcher(
        (action) => action.type === getChats.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getChats.fulfilled.type,
        (state, action: PayloadAction<{chats: IChat[]}>) => {
          state.offset = 0
            state.chats = [...action.payload.chats]
            // state.offset = current(state).offset + action.payload.chats.length || current(state).limit

            const totalLength = current(state).offset + action.payload.chats.length + 1;
            let offset = current(state).offset + action.payload.chats.length + 1;
            if (offset > totalLength)
                offset = totalLength;

            state.offset = offset || current(state).limit

            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getChats.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // load more
      .addMatcher(
        (action) => action.type === loadMoreChats.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === loadMoreChats.fulfilled.type,
        (state, action: PayloadAction<{chats: IChat[]}>) => {
            state.chats = [...current(state.chats), ...action.payload.chats]
            state.offset = current(state).offset + action.payload.chats.length || current(state).limit
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === loadMoreChats.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // update
      .addMatcher(
        (action) => action.type === updateChat.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === updateChat.fulfilled.type,
        (state, action: PayloadAction<{chat: IChat}>) => {
            state.chats = current(state.chats).map(chat => chat.id === action.payload.chat.id ? action.payload.chat : chat)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === updateChat.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // send
      .addMatcher(
        (action) => action.type === sendChatSms.pending.type,
        (state) => {
          // state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === sendChatSms.fulfilled.type,
        (state, action: PayloadAction<{message: ISms, id: string}>) => {
          console.log(current(state.chat))
          let chat = current(state.chat)?.id === action.payload.id ? current(state.chat) : null
          if (chat && state.chat) {
            chat = { ...current(state.chat), messages: [...current(state.chat)?.messages || [], action.payload.message ] }
            state.chat = chat
          }
          state.isLoading = false;

          if(!chat) return
          // console.log(chat)
          let current_chats = [...current(state.chats)]
          let tagged_chats = current_chats.filter(chat => chat.is_top)
          let untagged_chats = current_chats.filter(chat => !chat.is_top)
          if(chat.is_top) {
            const chat_index = tagged_chats.findIndex(it => it.id === chat?.id)
            if (chat_index === -1) {
                state.chats = [chat, ...tagged_chats, ...untagged_chats]
            } else {
                tagged_chats.splice(chat_index, 1)
                state.chats = [chat, ...tagged_chats, ...untagged_chats]
            }
          } else {
            const chat_index = untagged_chats.findIndex(it => it.id === chat?.id)
            if (chat_index === -1) {
                state.chats = [...tagged_chats, chat, ...untagged_chats]
            } else {
                untagged_chats.splice(chat_index, 1)
                state.chats = [...tagged_chats, chat, ...untagged_chats]
            }
          }
        }
      )
      .addMatcher(
        (action) => action.type === sendChatSms.rejected.type,
        (state, action: any) => {
          // state.isLoading = false;
        }
      )
      // autocomplete
      .addMatcher(
        (action) => action.type === autocompleteChats.pending.type,
        (state) => {
          // state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === autocompleteChats.fulfilled.type,
        (state, action: PayloadAction<{chats: IChat[] | null}>) => {
            state.autocomplete = action.payload.chats
        }
      )
      .addMatcher(
        (action) => action.type === autocompleteChats.rejected.type,
        (state, action: any) => {
          // state.isLoading = false;
        }
      )
  },
});

export const { clearChat, clearChats, clearAutocompleteChats, onNewSms, onNewChat, onOpenChat } = chatSlice.actions;

export default chatSlice.reducer;
