import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import { Request } from "../request";
import { toast } from "react-toastify";
import { IList } from "./list";

type IKeywordType = 'equal_to' | 'not_equal_to' | 'blank' | 'not_blank' | 'begins_with' | 'contains' | 'does_not_contain'

type IKeywordAction = 'add_to_trash' | 'add_to_dnc' | 'add_to_positive' | 'add_to_stop'

export interface IKeyword {
  id: string;
  title: string;
  text: string;
  status: boolean;
  type: IKeywordType;
  lists: IList[];
  createdAt: string;
}

type State = {
  keyword: IKeyword | null;
  keywords: IKeyword[];
  limit: number;
  offset: number;
  isLoading: boolean;
};

const initialState: State = {
  keyword: null,
  keywords: [],
  limit: 8,
  offset: 0,
  isLoading: false,
};


export const getKeyword = createAsyncThunk(
  "/keywords/get",
  async ({}: any, { rejectWithValue }) => {
    try {
        const response = await Request.get('keywords')
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getKeywords = createAsyncThunk(
  "/keywords/getAll",
  async (_, { getState, rejectWithValue }: any) => {
    try {
        const response = await Request.get(`keywords`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const createKeyword = createAsyncThunk(
  "/keywords/create",
  async (payload: any, { rejectWithValue }) => {
    try {
        const response = await Request.post('keywords', payload)
        toast('created', { type: 'success' })
        return response
    } catch (error: any) {
        toast('some error', { type: 'error' })
      return rejectWithValue(error);
    }
  }
);

export const updateKeyword = createAsyncThunk(
  "/keywords/update",
  async ({ id, payload }: any, { rejectWithValue }) => {
    try {
        const response = await Request.post(`keywords/${id}`, payload)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const deleteKeyword = createAsyncThunk(
  "/keywords/delete",
  async (id: string, { rejectWithValue }) => {
    try {
        const response = await Request.delete(`keywords/${id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);


const keywordSlice = createSlice({
  name: "keyword",
  initialState,
  reducers: { },
  extraReducers: (builder) => {
    builder
      // get
      .addMatcher(
        (action) => action.type === getKeyword.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getKeyword.fulfilled.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getKeyword.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // get all
      .addMatcher(
        (action) => action.type === getKeywords.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getKeywords.fulfilled.type,
        (state, action: PayloadAction<{keywords: IKeyword[]}>) => {
            state.keywords = [...action.payload.keywords]
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getKeywords.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // create
      .addMatcher(
        (action) => action.type === createKeyword.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === createKeyword.fulfilled.type,
        (state, action: PayloadAction<{keyword: IKeyword}>) => {
            state.keywords = [...current(state.keywords), action.payload.keyword]
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === createKeyword.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // update
      .addMatcher(
        (action) => action.type === updateKeyword.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === updateKeyword.fulfilled.type,
        (state, action: PayloadAction<{keyword: IKeyword}>) => {
            state.keywords = current(state).keywords.map(keyword => keyword.id === action.payload.keyword.id ? action.payload.keyword : keyword)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === updateKeyword.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // delete
      .addMatcher(
        (action) => action.type === deleteKeyword.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === deleteKeyword.fulfilled.type,
        (state, action: PayloadAction<{id: string}>) => {
            state.keywords = current(state).keywords.filter(keyword => keyword.id !== action.payload.id)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === deleteKeyword.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
  },
});

export const { } = keywordSlice.actions;

export default keywordSlice.reducer;
