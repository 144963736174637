import React from 'react';
import { ClipLoader } from 'react-spinners';

const Loader: React.FC = () => {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <ClipLoader size={50} color={"#2196f3"} loading={true} />
        </div>
    );
};

export default Loader;