import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import { Request } from "../request";
import { toast } from "react-toastify";
import { IChat } from "./chat";
import { IUser } from "./user";
import {ICampaign} from "../campaign/campaign.interface";

export interface ISms {
    id: string
    chat_number: string
    text: string
    contact_name: string
    direction: 'outgoing' | 'incoming'
    createdAt: string
    chat?: IChat
    user?: IUser
    campaign?: ICampaign
}

type State = {
  sms: ISms | null;
  sms_list: ISms[];
  autocomplete: ISms[] | null;
  limit: number;
  offset: number;
  isLoading: boolean;
};

const initialState: State = {
  sms: null,
  sms_list: [],
  autocomplete: null,
  limit: 32,
  offset: 0,
  isLoading: false,
};


export const getSms = createAsyncThunk(
  "/sms/get",
  async (id: string, { rejectWithValue }) => {
    try {
        const response = await Request.get(`sms/${id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getSmsList = createAsyncThunk(
  "/sms/getAll",
  async (params: any, { getState, rejectWithValue }: any) => {
    try {
        const { offset, limit } = getState().sms as State
        const response = await Request.get(
          `sms?text=${params.text}&offset=${0}&limit=${limit}&direction=${params.direction}&date_start=${params.start}&date_end=${params.end}&campaign=${params.campaign_id}&all=${params.show_all}&userId=${params.userId}`
        )
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const loadMoreSms = createAsyncThunk(
  "/sms/load",
  async (params: any, { getState, rejectWithValue }: any) => {
    try {
        const { offset, limit } = getState().sms as State
        const response = await Request.get(
          `sms?text=${params.text}&offset=${offset}&limit=${limit}&direction=${params.direction}&date_start=${params.start}&date_end=${params.end}&campaign=${params.campaign_id}&all=${params.show_all}&userId=${params.userId}`
        )
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const sendSms = createAsyncThunk(
  "/sms/send",
  async (payload: any, { getState, rejectWithValue }: any) => {
    try {
        const response = await Request.post('sms/send', payload)

        toast('sended', { type: 'success' })
        return response
    } catch (error: any) {
      toast('some error', { type: 'error' })
      return rejectWithValue(error);
    }
  }
);

export const autocompleteSms = createAsyncThunk(
  "/sms/autocomplete",
  async (text: string, { rejectWithValue }: any) => {
    try {
        const response = await Request.get(`sms/autocomplete?text=${text}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);


const smsSlice = createSlice({
  name: "sms",
  initialState,
  reducers: { 
    clearSms: (state) => {
        state.sms = null
    },
    clearSmsList: (state) => {
        state.sms_list = []
        state.offset = 0
    },
    clearAutocompleteSms: (state) => {
        state.autocomplete = null
    },
  },
  extraReducers: (builder) => {
    builder
      // get
      .addMatcher(
        (action) => action.type === getSms.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getSms.fulfilled.type,
        (state, action: PayloadAction<{sms: ISms}>) => {
          state.sms = action.payload.sms
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getSms.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // get all
      .addMatcher(
        (action) => action.type === getSmsList.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getSmsList.fulfilled.type,
        (state, action: PayloadAction<{sms_list: ISms[]}>) => {
            // state.offset = 0
            state.sms_list = [...action.payload.sms_list]
            // state.offset = current(state).offset + action.payload.sms_list.length || current(state).limit

            const totalLength = current(state).offset + action.payload.sms_list.length + 1;
            let offset = current(state).offset + action.payload.sms_list.length + 1;
            if (offset > totalLength)
                offset = totalLength;

            state.offset = offset || current(state).limit

            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getSmsList.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // load more
      .addMatcher(
        (action) => action.type === loadMoreSms.pending.type,
        (state) => {
          // state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === loadMoreSms.fulfilled.type,
        (state, action: PayloadAction<{sms_list: ISms[]}>) => {
          state.sms_list = [...current(state.sms_list), ...action.payload.sms_list]
          state.offset = current(state).offset + action.payload.sms_list.length || current(state).limit
        }
      )
      .addMatcher(
        (action) => action.type === loadMoreSms.rejected.type,
        (state, action: any) => {
          // state.isLoading = false;
        }
      )
      // autocomplete
      .addMatcher(
        (action) => action.type === autocompleteSms.pending.type,
        (state) => {
          // state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === autocompleteSms.fulfilled.type,
        (state, action: PayloadAction<{sms_list: ISms[] | null}>) => {
            state.autocomplete = action.payload.sms_list
        }
      )
      .addMatcher(
        (action) => action.type === autocompleteSms.rejected.type,
        (state, action: any) => {
          // state.isLoading = false;
        }
      )
  },
});

export const { clearSms, clearSmsList, clearAutocompleteSms } = smsSlice.actions;

export default smsSlice.reducer;
