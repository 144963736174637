import { useCallback, useEffect, useState } from "react";
import { Typography, Box, Button, Stack } from "@mui/material"
import { useDispatch, useSelector } from "../../../../redux/store";
import { Colors, KeyWords } from "../../../../types";
import { useTheme } from "../../../../services/theme";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import dayjs, { Dayjs } from 'dayjs';
import moment from "moment";
import {ICampaign} from "../../../../redux/campaign/campaign.interface";
import {updateCampaignSchedule} from "../../../../redux/campaign/campaign.request";

export const CampaignSchedule = ({campaign}: { campaign: ICampaign }) => {
    const [scheduleDate, setScheduleDate] = useState<Dayjs | null>(null);
    const [scheduleTime, setScheduleTime] = useState<Dayjs | null>(null);
    const [scheduleTimeFrom, setScheduleTimeFrom] = useState<Dayjs | null>(null);
    const [scheduleTimeTo, setScheduleTimeTo] = useState<Dayjs | null>(null);
    // const [scheduleTimeFrom, setScheduleTimeFrom] = useState<Dayjs | null>(dayjs('2024-05-29T06:00:00.000Z'));
    // const [scheduleTimeTo, setScheduleTimeTo] = useState<Dayjs | null>(dayjs('2024-05-29T06:00:00.000Z'));
    const [onMonday, setOnMonday] = useState(true)
    const [onTuesday, setOnTuesday] = useState(true)
    const [onWednesday, setOnWednesday] = useState(true)
    const [onThursday, setOnThursday] = useState(true)
    const [onFriday, setOnFriday] = useState(true)
    const [onSaturday, setOnSaturday] = useState(false)
    const [onSunday, setOnSunday] = useState(false)
    const [value, setValue] = useState<any>(null)

    const dispatch = useDispatch()
    const theme = useTheme()

    useEffect(() => {
        setScheduleDate(dayjs(moment(campaign.schedule?.start_date).format('MM-DD-YYYY')) || null)
        setScheduleTime(dayjs(campaign.schedule?.start_date) || null)
        setScheduleTimeFrom(dayjs(campaign.schedule?.start_time) || null)
        setScheduleTimeTo(dayjs(campaign.schedule?.end_time) || null)
        setOnMonday(campaign.schedule?.on_monday || false)
        setOnTuesday(campaign.schedule?.on_tuesday || false)
        setOnWednesday(campaign.schedule?.on_wednesday || false)
        setOnThursday(campaign.schedule?.on_thursday || false)
        setOnFriday(campaign.schedule?.on_friday || false)
        setOnSaturday(campaign.schedule?.on_saturday || false)
        setOnSunday(campaign.schedule?.on_sunday || false)
    }, [])

    const onSetSchedule = () => {
        const start_date = scheduleDate?.set('hour', scheduleTime?.get('hour') || 9).set('minutes', scheduleTime?.get('minutes') || 0)
        const start_time = scheduleDate?.set('hour', scheduleTimeFrom?.get('hour') || 0).set('minutes', scheduleTimeFrom?.get('minutes') || 0)
        const end_time = scheduleDate?.set('hour', scheduleTimeTo?.get('hour') || 23).set('minutes', scheduleTimeTo?.get('minutes') || 0)
        
        dispatch(updateCampaignSchedule({ 
            id: campaign.id, 
            payload: { 
                start_date: start_date || null,
                start_time: start_time?.toISOString() || null,
                end_time: end_time?.toISOString() || null,
                on_monday: onMonday,
                on_tuesday: onTuesday,
                on_wednesday: onWednesday,
                on_thursday: onThursday,
                on_friday: onFriday,
                on_saturday: onSaturday,
                on_sunday: onSunday,
            } 
        }))
    }

    return (
        <Box
            sx={{ 
                backgroundColor: theme === 'dark' ? Colors.MAIN_DARK : Colors.MAIN_WHITE,
                padding: 2,
            }}
        >
            <Typography variant="subtitle1">SCHEDULE</Typography>

            <Typography marginTop={1} variant="subtitle2">Current schedule: {scheduleDate?.format('dddd MM/YYYY h:mm A')}</Typography>

            <Stack
                direction={{ md: 'column', lg: 'row' }}
                alignItems={'center'} gap={1}
            >
                <Typography variant="subtitle2">Date</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DatePicker value={scheduleDate} onChange={(newValue) => setScheduleDate(newValue)}/>
                </LocalizationProvider>
                <Typography variant="subtitle2">Time</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer sx={{ marginBottom: 1 }} components={['TimePicker', 'TimePicker']}>
                        <TimePicker
                        label="select time"
                        value={scheduleTime}
                        onChange={(newValue) => setScheduleTime(newValue)}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </Stack>

            <Stack
                direction={{ md: 'column', lg: 'row' }}
                alignItems={'center'} gap={1}
                marginTop={4}
            >
                <Typography variant="subtitle2">Sending Times</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer sx={{ marginBottom: 1 }} components={['TimePicker', 'TimePicker']}>
                        <TimePicker
                            label="select time"
                            value={scheduleTimeFrom}
                            onChange={(newValue) => setScheduleTimeFrom(newValue)}
                        />
                    </DemoContainer>
                </LocalizationProvider>
                <Typography variant="subtitle2">To</Typography>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer sx={{ marginBottom: 1 }} components={['TimePicker', 'TimePicker']}>
                        <TimePicker
                            label="select time"
                            value={scheduleTimeTo}
                            onChange={(newValue) => setScheduleTimeTo(newValue)}
                        />
                    </DemoContainer>
                </LocalizationProvider>
            </Stack>
            
            <Stack
                style={{ display: 'flex', flexWrap: 'wrap', flexDirection: 'row', gap: 6, marginTop: 8 }}
            >
                <Typography variant="subtitle2">Sending Days</Typography>
                
                <Button onClick={() => setOnMonday(prev => !prev)} style={{ fontSize: 12 }} color={onMonday ? "success" : 'inherit'} variant="contained">Monday</Button>
                <Button onClick={() => setOnTuesday(prev => !prev)} style={{ fontSize: 12 }}color={onTuesday ? "success" : 'inherit'} variant="contained">Tuesday</Button>
                <Button onClick={() => setOnWednesday(prev => !prev)} style={{ fontSize: 12 }} color={onWednesday ? "success" : 'inherit'} variant="contained">Wednesday</Button>
                <Button onClick={() => setOnThursday(prev => !prev)} style={{ fontSize: 12 }} color={onThursday ? "success" : 'inherit'} variant="contained">Thursday</Button>
                <Button onClick={() => setOnFriday(prev => !prev)} style={{ fontSize: 12 }} color={onFriday ? "success" : 'inherit'} variant="contained">Friday</Button>
                <Button onClick={() => setOnSaturday(prev => !prev)} style={{ fontSize: 12 }} color={onSaturday ? "success" : 'inherit'} variant="contained">Saturday</Button>
                <Button onClick={() => setOnSunday(prev => !prev)} style={{ fontSize: 12 }} color={onSunday ? "success" : 'inherit'} variant="contained">Sunday</Button>
            </Stack>

            <Button disabled={campaign.status === 'created' ? false : true} onClick={onSetSchedule} style={{ marginTop: 10 }} variant="contained" fullWidth>Save</Button>
        </Box>
    )
}

