import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, Paper, Container, Button, Stack, Modal, Input, InputLabel, TextField, Divider, Select, FormControl, MenuItem, ButtonGroup, OutlinedInput, SelectChangeEvent, Grid } from "@mui/material"

export const Lookups = () => {
    const [openModal, setOpenModal] = useState(false);
    const [campaignType, setCampaignType] = useState('');

    const handleChange = (event: SelectChangeEvent) => {
        setCampaignType(event.target.value as string);
      };

    function createData(
        name: string,
        calories: number,
        fat: number,
        carbs: number,
        protein: number,
      ) {
        return { name, calories, fat, carbs, protein };
      }

    const rows = [
        createData('Frozen yoghurt', 159, 6.0, 24, 4.0),
        createData('Ice cream sandwich', 237, 9.0, 37, 4.3),
        createData('Eclair', 262, 16.0, 24, 6.0),
        createData('Cupcake', 305, 3.7, 67, 4.3),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
        createData('Gingerbread', 356, 16.0, 49, 3.9),
      ];
      

    function handleScroll(event: any) {
        const { scrollTop, scrollHeight, clientHeight } = event.target;
        const isAtBottom = scrollTop + clientHeight >= scrollHeight;
    
        if (isAtBottom) {
            
        }
    }

    return (
        <div>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                <Typography variant="h5">Lookups</Typography>
            </Box>

            <Container sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4, width: '100%' }}>
                <Grid container spacing={2}>
                    <Grid item xs={6} sm={3}>
                        <Button style={{ width: '100%', height: '100%' }} variant="contained">Upload Your List</Button>
                    </Grid>
                </Grid>
            </Container>
            
            <TableContainer onScroll={handleScroll} style={{ marginTop: 30, maxHeight: 'calc(100vh - 300px)' }} component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                    <TableHead>
                    <TableRow>
                        <TableCell>LIST NAME</TableCell>
                        <TableCell>CONTACTS UPLOADED</TableCell>
                        <TableCell>CONTACTS FILTERED</TableCell>
                        <TableCell>MOBILE</TableCell>
                        <TableCell>FIXED LINE</TableCell>
                        <TableCell>VOIP</TableCell>
                        <TableCell>TOLL FREE</TableCell>
                        <TableCell>UNKNOWN</TableCell>
                        <TableCell>COST</TableCell>
                        <TableCell>COMPLETED ON</TableCell>
                        <TableCell>STATUS</TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {rows.map((row) => (
                        <TableRow
                            key={row.name}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{row.name} </TableCell>
                            <TableCell>{row.calories}</TableCell>
                            <TableCell>{row.fat}</TableCell>
                            <TableCell>{row.carbs}</TableCell>
                            <TableCell>{row.protein}</TableCell>
                            <TableCell>{row.protein}</TableCell>
                            <TableCell>{row.protein}</TableCell>
                            <TableCell>{row.protein}</TableCell>
                            <TableCell>{row.protein}</TableCell>
                            <TableCell>{row.protein}</TableCell>
                            <TableCell>{row.protein}</TableCell>
                            <TableCell><Button variant="contained">Stop</Button></TableCell>
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal
                open={openModal}
                onClose={() => setOpenModal(false)}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
                >
                <Box 
                    sx={{ 
                        position: 'absolute' as 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '50%',
                        bgcolor: 'background.paper',
                        color: 'text.primary',
                        boxShadow: 24,
                        p: 4, 
                    }}
                >
                    
                </Box>
            </Modal>
        </div>
    )
}
