import { useCallback, useEffect, useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Typography, Box, TableContainer, Table, TableHead, TableRow, TableCell, 
    TableBody, Paper, Container, Button, Stack, Modal, InputLabel, TextField, Divider, SelectChangeEvent, Switch, Chip, OutlinedInput } from "@mui/material"
import { useDispatch, useSelector } from "../../../../redux/store";
import { IList, createList, deleteList, getLists, updateList } from "../../../../redux/slices/list";

export const Lists = () => {
    const [isOpenModal, setIsOpenModal] = useState<IList | boolean>(false)
    const { lists } = useSelector(store => store.list)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getLists())
    }, [])

    const handleDelete = (id: string) => {
        dispatch(deleteList(id))
    }

    return (
        <div>
            <Box sx={{ display: 'flex', alignItems: 'center' }}>
                <Typography variant="h5">Workspace</Typography><span> /lists</span>
            </Box>

            <Container sx={{ display: 'flex', justifyContent: 'space-between', marginTop: 4 }}>
               <Box>
                    <Typography variant="h6">Lists</Typography>
                    <Typography sx={{ fontSize: 12 }} variant="subtitle2">Manage your lists here</Typography>
               </Box>
               <Stack direction={{ xs: 'column', sm: 'row' }} spacing={2}>
                    <Button onClick={() => setIsOpenModal(true)} size="small" variant="contained">Create List</Button>
               </Stack>
            </Container>

            <TableContainer style={{ marginTop: 30, maxHeight: 'calc(100vh - 300px)' }} component={Paper}>
                <Table stickyHeader aria-label="sticky table" sx={{ minWidth: 650 }}>
                    <TableHead>
                    <TableRow>
                        <TableCell>NAME</TableCell>
                        <TableCell>
                        </TableCell>
                    </TableRow>
                    </TableHead>
                    <TableBody>
                    {lists.map((list) => (
                        <TableRow
                            key={list.id}
                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                        >
                            <TableCell component="th" scope="row">{list.name} </TableCell>                        
                            <TableCell component="th" scope="row">
                                <Box sx={{ display: 'flex', gap: 1 }}>
                                    {/* <div style={{ cursor: 'pointer' }} onClick={() => handleDelete(list.id)}>
                                        {<FontAwesomeIcon color="red" icon={"fa-solid fa-trash".split(' ') as any}></FontAwesomeIcon>}
                                    </div> */}
                                    <div style={{ cursor: 'pointer' }} onClick={() => setIsOpenModal(list)}>
                                        {<FontAwesomeIcon color="skyblue" icon={"fa-solid fa-pen-to-square".split(' ') as any}></FontAwesomeIcon>}
                                    </div>
                                </Box>
                            </TableCell>                        
                        </TableRow>
                    ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Modal 
                open={!!isOpenModal} onClose={() => setIsOpenModal(false)} 
                aria-labelledby="modal-modal-title" 
                aria-describedby="modal-modal-description" >
                    <ModalContent list={isOpenModal} close={() => setIsOpenModal(false)}></ModalContent>
            </Modal>
        </div>
    )
}

const ModalContent = ({ list, close }: { list: IList | boolean, close: () => void }) => {
    const [name, setName] = useState('');
    const dispatch = useDispatch()

    const handleSave = () => {
        if(!name) return

        if (list && typeof list !== 'boolean') {
            dispatch(updateList({ id: list.id, payload: { name } }))
        } else {
            dispatch(createList({ name }))
        }
    }

    useEffect(() => {
        if (list && typeof list !== 'boolean') {
            setName(list.name)
        }

        // return () => { dispatch(clearTemplate()) }
    }, [])

    return (
        <Box 
            sx={{ 
                position: 'absolute' as 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '50%',
                bgcolor: 'background.paper',
                color: 'text.primary',
                boxShadow: 24,
                p: 4, 
            }}
        >
            <Box sx={{ display: 'flex', justifyContent: 'space-between', /*alignItems: 'center'*/ }}>
                <Typography variant="h6">{list && typeof list !== 'boolean' ? 'Update' : 'Create'} list</Typography>
                <Box style={{ cursor: 'pointer' }} onClick={close}>
                    {<FontAwesomeIcon icon={"fa-solid fa-x".split(' ') as any}></FontAwesomeIcon>}
                </Box>
            </Box>
            <Divider style={{ marginTop: 10 }}></Divider>

            <TextField value={name} onChange={e => setName(e.target.value)} style={{ marginTop: 30 }} placeholder="Title" fullWidth></TextField>

            <Box sx={{ marginTop: '20px', display: 'flex', justifyContent: 'flex-end' }}>
                <Button onClick={handleSave} size="large" variant="contained">Save</Button>
            </Box>
        </Box>
    )
}
