import { Box, Button, TextField, Typography } from "@mui/material"
import { ThemeSwitcher, useTheme } from "../../services/theme"
import { Colors } from "../../types"
import { useEffect, useState } from "react"
import '../../App.scss'
import { useAuth } from "../../services/authContext"
import { useNavigate } from "react-router-dom"

export const SignIn = () => {
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const { SignIn, isUserLogged } = useAuth()
    const navigate = useNavigate()
    const theme = useTheme()

    useEffect(() => {
        document.body.className = 'body_overflow_hidden'
        return () => { document.body.className = document.body.className.replace("body_overflow_hidden","") }
    }, [])

    useEffect(() => {
        if(isUserLogged) navigate('/admin')
    }, [isUserLogged])

    const onSubmit = () => {
        SignIn({ email, password })
    }

    return (
    <Box sx={{ height: '100vh', 
        backgroundColor: theme === 'dark' ? Colors.SECONDARY_DARK : Colors.SECONDARY_LIGTH,
        color: theme === 'dark' ? Colors.MAIN_WHITE : Colors.MAIN_BLACK,
        display: 'flex', flexDirection: 'column', alignItems: 'center'
    }}>
        <Box sx={{ alignSelf: 'flex-end', marginRight: 10 }}><ThemeSwitcher></ThemeSwitcher></Box>
        <Box sx={{ 
            display: 'flex', justifyContent: 'center', flexDirection: 'column', 
            marginTop: 10, width: '50%', minWidth: '300px'
        }}>
            <Typography style={{ textAlign: 'center' }} variant="h5">SignIn</Typography>
            <TextField value={email} onChange={(e) => setEmail(e.target.value)} style={{ marginTop: 20 }} placeholder="Email" fullWidth></TextField>
            <TextField value={password} onChange={(e) => setPassword(e.target.value)} style={{ marginTop: 20 }} placeholder="Password" fullWidth></TextField>
            <Button onClick={onSubmit} size="large" style={{ marginTop: 20 }} variant="contained">Sign In</Button>
        </Box>
    </Box>)
}