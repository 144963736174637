import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import { Request } from "../request";
import { toast } from "react-toastify";

interface IField {
    key: string
    value: string
    contact_number: string
}

export interface IContact {
  number: string;
  first_name: string;
  last_name: string;
  fields: IField[]
  lists: { list_id: string, name: string }[]
  notes: string
  createdAt: string;
}

type State = {
  contact: IContact | null;
  contacts: IContact[];
  autocomplete: IContact[] | null;
  limit: number;
  offset: number;
  isLoading: boolean;
};

const initialState: State = {
  contact: null,
  contacts: [],
  autocomplete: null,
  limit: 32,
  offset: 0,
  isLoading: false,
};


export const getContact = createAsyncThunk(
  "/contacts/get",
  async (id: string, { rejectWithValue }) => {
    try {
        const response = await Request.get(`contacts/${id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getContacts = createAsyncThunk(
  "/contacts/getAll",
  async ({ list_id }: { list_id: string }, { getState, rejectWithValue }: any) => {
    try {
        const { offset, limit } = getState().contacts as State
        const response = await Request.get(`contacts?offset=${0}&limit=${limit}&list=${list_id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const loadMoreContacts = createAsyncThunk(
  "/contacts/load",
  async ({ list_id, text}: { list_id: string, text: string }, { getState, rejectWithValue }: any) => {
    try {
        const { offset, limit } = getState().contacts as State
        const response = await Request.get(`contacts?offset=${offset}&limit=${limit}&list=${list_id}&text=${text}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const autocompleteContacts = createAsyncThunk(
  "/contacts/autocomplete",
  async (text: string, { rejectWithValue }: any) => {
    try {
        const response = await Request.get(`contacts/autocomplete?text=${text}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const updateContact = createAsyncThunk(
  "/contacts/update",
  async ({ id, payload }: { id: string, payload: any }, { rejectWithValue }: any) => {
    try {
        const response = await Request.post(`contacts/${id}`, payload)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const importContact = createAsyncThunk(
  "/contacts/import",
  async ({ payload }: { payload: any }, { rejectWithValue }: any) => {
    try {
        const response = await Request.post(`contacts/import`, payload)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);


const contactSlice = createSlice({
  name: "contact",
  initialState,
  reducers: { 
    clearContacts: (state) => {
        state.contacts = []
        state.offset = 0
    },
    clearAutocompleteContacts: (state) => {
        state.autocomplete = null
    }
  },
  extraReducers: (builder) => {
    builder
      // get
      .addMatcher(
        (action) => action.type === getContact.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getContact.fulfilled.type,
        (state, action: PayloadAction<{contact: IContact}>) => {
          state.contact = action.payload.contact
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getContact.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // get all
      .addMatcher(
        (action) => action.type === getContacts.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getContacts.fulfilled.type,
        (state, action: PayloadAction<{contacts: IContact[]}>) => {
          state.offset = 0
          state.contacts = [...action.payload.contacts]
          // state.offset = current(state).offset + action.payload.contacts.length || current(state).limit

            const totalLength = current(state).offset + action.payload.contacts.length + 1;
            let offset = current(state).offset + action.payload.contacts.length + 1;
            if (offset > totalLength)
                offset = totalLength;

            state.offset = offset || current(state).limit

          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getContacts.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
         // load more
         .addMatcher(
          (action) => action.type === loadMoreContacts.pending.type,
          (state) => {
            // state.isLoading = true;
          }
        )
        .addMatcher(
          (action) => action.type === loadMoreContacts.fulfilled.type,
          (state, action: PayloadAction<{contacts: IContact[]}>) => {
            state.contacts = [...current(state.contacts), ...action.payload.contacts]
            // state.offset = current(state).offset + action.payload.contacts.length || current(state).limit

              const totalLength = current(state).offset + action.payload.contacts.length + 1;
              let offset = current(state).offset + action.payload.contacts.length + 1;
              if (offset > totalLength)
                  offset = totalLength;

              state.offset = offset || current(state).limit

          }
        )
        .addMatcher(
          (action) => action.type === loadMoreContacts.rejected.type,
          (state, action: any) => {
            // state.isLoading = false;
          }
        )
      // autocomplete
      .addMatcher(
        (action) => action.type === autocompleteContacts.pending.type,
        (state) => {
          // state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === autocompleteContacts.fulfilled.type,
        (state, action: PayloadAction<{contacts: IContact[] | null}>) => {
            state.autocomplete = action.payload.contacts
        }
      )
      .addMatcher(
        (action) => action.type === autocompleteContacts.rejected.type,
        (state, action: any) => {
          // state.isLoading = false;
        }
      )
      // update
      .addMatcher(
        (action) => action.type === updateContact.pending.type,
        (state) => {
          // state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === updateContact.fulfilled.type,
        (state, action: PayloadAction<{contact: IContact}>) => {
            state.contact = action.payload.contact
        }
      )
      .addMatcher(
        (action) => action.type === updateContact.rejected.type,
        (state, action: any) => {
          // state.isLoading = false;
        }
      )
      // import
      .addMatcher(
        (action) => action.type === importContact.pending.type,
        (state) => {
          // state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === importContact.fulfilled.type,
        (state, action: PayloadAction<{contact: IContact}>) => {
            // state.contact = action.payload.contact
        }
      )
      .addMatcher(
        (action) => action.type === importContact.rejected.type,
        (state, action: any) => {
          // state.isLoading = false;
        }
      )
  },
});

export const { clearContacts, clearAutocompleteContacts } = contactSlice.actions;

export default contactSlice.reducer;
