import { Request } from "../redux/request";

const inMemoryJWTService = () => {
  let inMemoryJWT: string | null = null;
  let refreshTimeoutId: any = null;

  const refreshToken = (expiration: number) => {
    const timeoutTrigger = expiration - 10000;

    refreshTimeoutId = setTimeout(() => {
        Request.post('refresh')
            .then(res => {
                console.log(res)
                const { accessToken, accessTokenExpiration } = res;
                setToken(accessToken, accessTokenExpiration);
            })
            .catch(err => console.log(err))
    }, timeoutTrigger);
  };

  const abortRefreshToken = () => {
    if (refreshTimeoutId) {
      clearTimeout(refreshTimeoutId);
    }
  };

  const getToken = () => inMemoryJWT;

  const setToken = (token: string | null, tokenExpiration: number | null) => {
    inMemoryJWT = token;
    if(tokenExpiration) refreshToken(tokenExpiration);
  };

  const deleteToken = () => {
    inMemoryJWT = null;
    abortRefreshToken();
    localStorage.setItem('logout', String(Date.now()));
  };

  return {
    getToken,
    setToken,
    deleteToken,
  };
};

export default inMemoryJWTService();
