import { createSlice, createAsyncThunk, PayloadAction, current } from "@reduxjs/toolkit";
import { Request } from "../request";
import { toast } from "react-toastify";

export interface ITemplate {
  id: string;
  name: string;
  text: string;
  createdAt: string;
}

type State = {
  template: ITemplate | null;
  templates: ITemplate[];
//   limit: number;
//   offset: number;
  isLoading: boolean;
};

const initialState: State = {
  template: null,
  templates: [],
//   limit: 8,
//   offset: 0,
  isLoading: false,
};


export const getTemplate = createAsyncThunk(
  "/templates/get",
  async ({}: any, { rejectWithValue }) => {
    try {
        const response = await Request.get('templates')
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const getTemplates = createAsyncThunk(
  "/templates/getAll",
  async (_, { getState, rejectWithValue }: any) => {
    try {
        const response = await Request.get(`templates`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const createTemplate = createAsyncThunk(
  "/templates/create",
  async (payload: any, { rejectWithValue }) => {
    try {
        const response = await Request.post('templates', payload)
        toast('created', { type: 'success' })
        return response
    } catch (error: any) {
        toast('some error', { type: 'error' })
      return rejectWithValue(error);
    }
  }
);

export const updateTemplate = createAsyncThunk(
  "/templates/update",
  async ({ id, payload }: any, { rejectWithValue }) => {
    try {
        const response = await Request.post(`templates/${id}`, payload)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

export const deleteTemplate = createAsyncThunk(
  "/templates/delete",
  async (id: string, { rejectWithValue }) => {
    try {
        const response = await Request.delete(`templates/${id}`)
        return response
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);


const templateSlice = createSlice({
  name: "template",
  initialState,
  reducers: {
    clearTemplate: (state) => {
        state.template = null
    }
  },
  extraReducers: (builder) => {
    builder
      // get
      .addMatcher(
        (action) => action.type === getTemplate.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getTemplate.fulfilled.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getTemplate.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // get all
      .addMatcher(
        (action) => action.type === getTemplates.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === getTemplates.fulfilled.type,
        (state, action: PayloadAction<{templates: ITemplate[]}>) => {
            state.templates = [...action.payload.templates]
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === getTemplates.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // create
      .addMatcher(
        (action) => action.type === createTemplate.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === createTemplate.fulfilled.type,
        (state, action: PayloadAction<{template: ITemplate}>) => {
            state.templates = [...current(state.templates), action.payload.template]
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === createTemplate.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // update
      .addMatcher(
        (action) => action.type === updateTemplate.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === updateTemplate.fulfilled.type,
        (state, action: PayloadAction<{template: ITemplate}>) => {
            state.templates = current(state).templates.map(template => template.id === action.payload.template.id ? action.payload.template : template)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === updateTemplate.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
      // delete
      .addMatcher(
        (action) => action.type === deleteTemplate.pending.type,
        (state) => {
          state.isLoading = true;
        }
      )
      .addMatcher(
        (action) => action.type === deleteTemplate.fulfilled.type,
        (state, action: PayloadAction<{id: string}>) => {
            state.templates = current(state).templates.filter(template => template.id !== action.payload.id)
            state.isLoading = false;
        }
      )
      .addMatcher(
        (action) => action.type === deleteTemplate.rejected.type,
        (state, action: any) => {
          state.isLoading = false;
        }
      )
  },
});

export const { clearTemplate } = templateSlice.actions;

export default templateSlice.reducer;
