import {createAsyncThunk} from "@reduxjs/toolkit";
import {Request} from "../request";
import {IAIDevicesData, IUpdateDevicesData} from "./sim.interface";

export const getDev = createAsyncThunk(
    "/sim/",
    async (dev: string, {rejectWithValue}) => {
        try {
            return await Request.get(`sim/${dev}`);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const getDevList = createAsyncThunk(
    "/sim/list",
    async (_, {rejectWithValue}) => {
        try {
            return await Request.get(`sim`);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const restartDevice = createAsyncThunk(
    "/sim/restart/device/port",
    async ({dev, port}:{dev: string, port: string}, {rejectWithValue}) => {
        try {
            return await Request.post(`sim/restart/${dev}/${port}`);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const stopDevice = createAsyncThunk(
    "/sim/stop/device/port",
    async ({dev, port}:{dev: string, port: string}, {rejectWithValue}) => {
        try {
            return await Request.post(`sim/stop/${dev}/${port}`);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const startDevices = createAsyncThunk(
    "/sim/start/devices",
    async ({dev}:{dev: string}, {rejectWithValue}) => {
        try {
            return await Request.post(`sim/start/${dev}`);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const restartDevices = createAsyncThunk(
    "/sim/restart/devices",
    async ({dev}:{dev: string}, {rejectWithValue}) => {
        try {
            return await Request.post(`sim/restart/${dev}`);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const stopDevices = createAsyncThunk(
    "/sim/stop/devices",
    async ({dev}:{dev: string}, {rejectWithValue}) => {
        try {
            return await Request.post(`sim/stop/${dev}`);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const updateDevice = createAsyncThunk(
    "/sim/update/devices/port",
    async ({dev, port, payload}:{dev: string, port: string, payload: IUpdateDevicesData}, {rejectWithValue}) => {
        try {
            return await Request.post(`sim/update/${dev}/${port}`, payload);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const aiDevices = createAsyncThunk(
    "/sim/ai/device",
    async ({dev, payload}:{dev: string, payload: IAIDevicesData}, {rejectWithValue}) => {
        try {
            return await Request.post(`sim/ai/${dev}`, payload);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const devicesList = createAsyncThunk(
    "/sim/device/list",
    async (_, {rejectWithValue}) => {
        try {
            return await Request.get(`/sim/device/list/`);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deviceMessage = createAsyncThunk(
    "/sim/message/",
    async ({dev}:{dev: string}, {rejectWithValue}) => {
        try {
            return await Request.get(`/sim/message/${dev}`);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);

export const deviceInfo = createAsyncThunk(
    "/sim/info/",
    async ({dev}:{dev: string}, {rejectWithValue}) => {
        try {
            return await Request.get(`/sim/info/${dev}`);
        } catch (error: any) {
            return rejectWithValue(error);
        }
    }
);
