import { LineChart } from "@mui/x-charts"
import { ThemeSwitcher } from "../../../services/theme"
import { Button, Container, Typography, Box } from "@mui/material"
import { useEffect, useState } from "react"
import { Request } from "../../../redux/request"
import moment from "moment"
import { useAuth } from "../../../services/authContext"

export const Dashboard = () => {
    const { me } = useAuth()
    const [sendStats, setSendStats] = useState([])
    const [obtainStats, setObtainStats] = useState([])
    const [isLoadServersStat, setIsLoadServersStat] = useState(false)
    const [serverStats, setServerStats] = useState<{name: string, active: number}[]>([])

    useEffect(() => {
        const f = async () => {
            const response = await Request.get('dashboard')
           setSendStats(response.send_stats)
           setObtainStats(response.receive_stats)
        }
        f()
    }, [])

    useEffect(() => {
        const f = async () => {
            setIsLoadServersStat(true)
            try {
                if(me?.role === 'admin') {
                    const response = await Request.get('dashboard/servers')
                    setServerStats(response.servers)
                }
            } catch {}
            finally {
                setIsLoadServersStat(false)
            }
        }
        f()
    }, [])

    return (
            <div style={{ width: '100%' }}>

                {me?.role === 'admin' && <Box>
                    {
                        isLoadServersStat ? 'Loading' 
                        : <Box sx={{ marginBottom: 4 }}>
                                <Box sx={{ display: 'flex', marginBottom: 2 }}>
                                    <Typography style={{ width: 160, whiteSpace: 'nowrap' }}>Server name</Typography>
                                    <Typography style={{ width: 100, whiteSpace: 'nowrap' }}>Active</Typography>
                                </Box>
                            {
                                serverStats.map(server => 
                                <Box sx={{ display: 'flex' }} key={server.name}>
                                    <Typography style={{ width: 160, whiteSpace: 'nowrap' }}>{server.name}</Typography>
                                    <Typography style={{ width: 100, whiteSpace: 'nowrap', color: 'green' }}>{server.active}</Typography>
                                </Box>)
                            }
                        </Box>
                    }
                </Box>}

                <Typography>Received sms last 10 days</Typography>
                <LineChart
                    xAxis={[
                        {
                            scaleType: 'time',
                            data:
                                sendStats && sendStats.length > 0
                                    ? sendStats.map((it: any) => new Date(it.date))
                                    : [new Date()],
                            valueFormatter: (value) => moment(value).format('DD/MM'),
                        },
                    ]}
                    series={[
                        {
                            data:
                                sendStats && sendStats.length > 0
                                    ? sendStats.map((it: any) => it.sms_count)
                                    : [0],
                        },
                    ]}
                    height={300}
                />

                <Typography>Received sms last 10 days</Typography>
                <LineChart
                    xAxis={[
                        {
                            scaleType: 'time',
                            data:
                                obtainStats && obtainStats.length > 0
                                    ? obtainStats.map((it: any) => new Date(it.date))
                                    : [new Date()],
                            valueFormatter: (value) => moment(value).format('DD/MM'),
                        },
                    ]}
                    series={[
                        {
                            data:
                                obtainStats && obtainStats.length > 0
                                    ? obtainStats.map((it: any) => it.sms_count)
                                    : [0],
                        },
                    ]}
                    height={300}
                />
            </div>
    )
}