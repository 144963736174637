import {createSlice, PayloadAction} from "@reduxjs/toolkit";
import {IDevicesData, IDeviceOneData, IDeviceList, IDeviceMessages, IDeviceInfo} from "./sim.interface";
import {
    aiDevices, deviceInfo, deviceMessage, devicesList,
    getDev,
    getDevList,
    restartDevice,
    restartDevices,
    startDevices,
    stopDevice,
    stopDevices,
    updateDevice
} from "./sim.request";

type SimState = {
    devices: IDevicesData,
    deviceOne: IDeviceOneData[]
    deviceList: IDeviceList
    deviceMessages: IDeviceMessages[]
    deviceInfoByPort: IDeviceInfo[]
    isLoading: boolean
};

const initialState: SimState = {
    devices: {},
    deviceOne: [],
    deviceList: {},
    deviceMessages: [],
    deviceInfoByPort: [],
    isLoading: false
};

const sim = createSlice({
    name: "sim",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            // get list
            .addCase(getDevList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDevList.fulfilled, (state, action: PayloadAction<IDevicesData>) => {
                state.isLoading = false;
                state.devices = action.payload;
            })
            .addCase(getDevList.rejected, (state) => {
                state.isLoading = false;
            })
            // get
            .addCase(getDev.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(getDev.fulfilled, (state, action: PayloadAction<IDeviceOneData[]>) => {
                state.isLoading = false;
                state.deviceOne = action.payload;
            })
            .addCase(getDev.rejected, (state) => {
                state.isLoading = false;
            })
            // restart
            .addCase(restartDevice.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(restartDevice.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(restartDevice.rejected, (state) => {
                state.isLoading = false;
            })
            // stop
            .addCase(stopDevice.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(stopDevice.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(stopDevice.rejected, (state) => {
                state.isLoading = false;
            })
            // start
            .addCase(startDevices.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(startDevices.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(startDevices.rejected, (state) => {
                state.isLoading = false;
            })
            // restart all
            .addCase(restartDevices.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(restartDevices.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(restartDevices.rejected, (state) => {
                state.isLoading = false;
            })
            // stop all
            .addCase(stopDevices.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(stopDevices.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(stopDevices.rejected, (state) => {
                state.isLoading = false;
            })
            // update
            .addCase(updateDevice.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(updateDevice.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(updateDevice.rejected, (state) => {
                state.isLoading = false;
            })
            // ai control
            .addCase(aiDevices.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(aiDevices.fulfilled, (state) => {
                state.isLoading = false;
            })
            .addCase(aiDevices.rejected, (state) => {
                state.isLoading = false;
            })
            // devices list
            .addCase(devicesList.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(devicesList.fulfilled, (state, action: PayloadAction<IDeviceList>) => {
                state.isLoading = false;
                state.deviceList = action.payload;
            })
            .addCase(devicesList.rejected, (state, action) => {
                state.isLoading = false;
            })
            // device message
            .addCase(deviceMessage.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deviceMessage.fulfilled, (state, action: PayloadAction<IDeviceMessages[]>) => {
                state.isLoading = false;
                state.deviceMessages = action.payload;
            })
            .addCase(deviceMessage.rejected, (state, action) => {
                state.isLoading = false;
            })
            // device info
            .addCase(deviceInfo.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(deviceInfo.fulfilled, (state, action: PayloadAction<IDeviceInfo[]>) => {
                state.isLoading = false;
                state.deviceInfoByPort = action.payload;
            })
            .addCase(deviceInfo.rejected, (state, action) => {
                state.isLoading = false;
            })
    },
});

export const {} = sim.actions;

export default sim.reducer;